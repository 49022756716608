import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { NotFoundComponent } from './pages/shared/components/not-found/not-found.component';
import { UnauthorizedComponent } from './pages/shared/components/unauthorized/unauthorized.component';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule),
    canActivate: [MsalGuard],
    data: {
      breadcrumb: null
    }
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'unauthorized',
    component: UnauthorizedComponent
  },
  {
    path: 'auth/microsoft',
    redirectTo: 'home/campaign-management',
    pathMatch: 'full'
  },
  {
    path: '',
    redirectTo: 'home/campaign-management',
    pathMatch: 'full',
  },
  {
    path: '**',
    component: NotFoundComponent,
    loadChildren: () => import('../app/pages/shared/components/not-found/not-found.module').then(m => m.NotFoundModule)
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
