import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, switchMap } from 'rxjs';
import { CommonHeaders } from 'src/app/pages/shared/services/common-headers';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class VersionRequestService extends CommonHeaders {

    constructor(
        protected override http: HttpClient,
        protected override store: Store,
    ) {
        super(http, store);
    }

    getLastVersion(versionType): Observable<any> {
        let filter = '';
        if (versionType === 'CMS') {
            filter = '&filters[version_type][$eq]=CMS&sort=createdAt:desc&pagination[limit]=1'
        };
        return this.getStrapiHeaders(true).pipe(
            switchMap(({ headers }) => this.http.get<any>(
                `${environment.strapiLinkApi}/versions?populate=*${filter}`,
                { headers }
            ))
        );
    }

    getVersionDetails(id: number): Observable<any> {
        return this.getStrapiHeaders(true).pipe(
            switchMap(({ headers }) => this.http.get<any>(
                `${environment.strapiLinkApi}/versions/${id}?populate=deep`,
                { headers }
            ))
        );
    }
}
