import { createActionGroup, props } from "@ngrx/store";


export const lastVersionActions = createActionGroup({
    source: 'Version',
    events: {
        'try get last version': props<{ versionType: string }>(),
        'success get last version': props<{ lastVersion: any }>(),
        'fail get last version': props<{ error: Error }>(),
    },
});
