import { createActionGroup, emptyProps, props } from "@ngrx/store";


export const releaseNoteActionsForCMS = createActionGroup({
    source: 'ReleaseNote For CMS',
    events: {
        'try get one releaseNote  for cms': props<{ releaseType: string, versionId: any }>(),
        'success get one releaseNote  for cms': props<{ releaseNoteForCMS: any }>(),
        'fail get one releaseNote  for cms': props<{ error: Error }>(),
    },
});


export const releaseNoteActionsForTV = createActionGroup({
    source: 'ReleaseNote For TV',
    events: {
        'try get one releaseNote for TV': props<{ releaseType: string, versionId: any }>(),
        'success get one releaseNote for TV': props<{ releaseNoteForTV: any }>(),
        'fail get one releaseNote for TV': props<{ error: Error }>(),
    },
});