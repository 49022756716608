import * as moment from "moment";
import { RoleSummary, StrapiUserRoleAttributes, StrapiUserRoleResponse, UserRoleDisplayNames } from "src/app/pages/home/inner-pages/user-management/user.constant";

export const fillTVs = (tvsResponse) => {
    const tvs: any[] = [];

    tvsResponse.data.forEach(tv => {
        const attributes = tv.attributes;

        tvs.push({
            id: tv.id,
            name: attributes.name,
            status: attributes.status
        });
    });

    return tvs;
}

export const fillContents = (contentsResponse) => {
    const contents: any[] = [];

    contentsResponse?.data.forEach(content => {
        const attributes = content.attributes;
        contents.push({
            contentId: content.id,
            mediaId: content.attributes.mediaId,
            mediaURL: content.attributes.mediaURL,
            name: attributes.mediaURL.split('/').at(-1),
            mime: attributes.contentType,
            createdAt: attributes.createdAt,
            sortingPriority: attributes.sortingPriority
        });
    });

    const compareSortingPriority = (a, b) => {
        if (a.sortingPriority < b.sortingPriority) {
            return -1;
        }
        if (a.sortingPriority > b.sortingPriority) {
            return 1;
        }
        return 0;
    };

    contents.sort(compareSortingPriority);

    return contents;
}

/* To fill the isLive property for campaign,
 when the campaign is published and the date between its start and end dates.*/

export const isLive = (campaign) => {
    let isLive = false;
    if (campaign.attributes.published) {
        if (!campaign.attributes.startDate && !campaign.attributes.endDate) {
            isLive = true;
        } else {

            let currentDate = moment();
            if (currentDate) {
                let startDate = moment(campaign.attributes.startDate);
                let endDate = moment(campaign.attributes.endDate);
                isLive = currentDate.isBetween(startDate, endDate, 'days', '[]');
            }
        }
    }

    return isLive;
}

export const DataURIToBlob = (dataURI: string) => {
    const splitDataURI = dataURI.split(',')
    const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

    const ia = new Uint8Array(byteString.length)
    for (let i = 0; i < byteString.length; i++)
        ia[i] = byteString.charCodeAt(i)

    return new Blob([ia], { type: mimeString })
}

export const userHasRole = (_currentUserRoles: any, _typeOfRole: string): boolean => {
    if (!_currentUserRoles.length) {
        return false;
    }

    return _currentUserRoles.some(role => role?.role_name === _typeOfRole);
}

export const fillUserRoles = (_userRoles: StrapiUserRoleResponse): RoleSummary[] => {
    const _nameOfRoles: RoleSummary[] = [];

    _userRoles.data.forEach(role => {
        const attributes = role.attributes;

        _nameOfRoles.push({
            id: role.id,
            role_name: attributes.role_name,
        });
    });

    return _nameOfRoles;
}

export const transformTheNameOfRoles = (_userRoles: any[]) => {

    return _userRoles.map(role => ({
        ...role,
        displayName: UserRoleDisplayNames[role.role_name]
    }));
} 
