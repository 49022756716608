import { createActionGroup, emptyProps, props } from "@ngrx/store";

export const uploadFileActions = createActionGroup({
  source: 'File',
  events: {
    'try upload file': props<{ campaignContent: any }>(),
    'success upload file': emptyProps(),
    'add file to campaign form': props<{ response, campaignContent: any }>(),
    'fail upload file': props<{ error: Error }>(),
  },
});

export const uploadUserImageFileActions = createActionGroup({
  source: 'File',
  events: {
    'try upload user image file': props<{ userImageContent: any }>(),
    'success upload user image file': emptyProps(),
    'update user image file on strapi': props<{ response, userImageContent: any }>(),
    'fail upload user image file': props<{ error: Error }>(),
  },
});


export const deleteFileActions = createActionGroup({
  source: 'File',
  events: {
    'try delete file': props<{ id: any }>(),
    'success delete file': props<{ id: any }>(),
    'fail delete file': props<{ error: Error }>(),
    'delete file from campaign form': emptyProps(),
  },
});