import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import * as digitalSignageAction from './actions.digital';
import { Router } from '@angular/router';
import { DigitalSignageRequestService } from 'src/app/pages/home/inner-pages/Digital-signage/digital-signage-services/digital-signage-request.service';
@Injectable()
export class DigitalSignageEffect {


    digitalSignageList$ = createEffect(() => this.actions$.pipe(
        ofType(digitalSignageAction.digitalSignageListActions.tryGetDigitalSignageList),

        switchMap((page) => this.digitalSignageRequestService.getDigitalSignageList(page)),

        map((data) => digitalSignageAction.digitalSignageListActions.successGetDigitalSignageList({ digitalSignageList: data })),
        catchError((error: any, caught) => {
            this.store.dispatch(digitalSignageAction.digitalSignageListActions.failGetDigitalSignageList({ error }));
            return caught;
        })
    ));

    digitalSignageDetails$ = createEffect(() => this.actions$.pipe(
        ofType(digitalSignageAction.getDigitalSignageDetailsActions.tryGetDigitalSignageDetails),
        switchMap(({ digitalSignageId }) => this.digitalSignageRequestService.getDigitalSignageDetails(digitalSignageId)),
        map((data) => digitalSignageAction.getDigitalSignageDetailsActions.successGetDigitalSignageDetails({ digitalSignageDetails: { data } })),
        catchError((error: any, caught) => {
            if (error?.status === 401 || error?.status === 403) {
                this.router.navigate(['/']);
            } else if (error?.status === 404) {
                this.store.dispatch(digitalSignageAction.getDigitalSignageDetailsActions.failGetDigitalSignageDetails({ error }));
                this.router.navigate(['/']);
            } else {
                this.store.dispatch(digitalSignageAction.getDigitalSignageDetailsActions.failGetDigitalSignageDetails({ error }));
            }
            return caught;
        })
    ));

    updateDigitalSignage$ = createEffect(() => this.actions$.pipe(
        ofType(digitalSignageAction.updateDigitalSignageActions.tryUpdateDigitalSignage),
        switchMap(({ digitalSignageId, digitalSignage, status }) => this.digitalSignageRequestService.updateDigitalSignage(digitalSignageId, digitalSignage, status)),
        map((data) => digitalSignageAction.updateDigitalSignageActions.successUpdateDigitalSignage({ digitalSignageDetails: { data } })),
        catchError((error: any, caught) => {
            this.store.dispatch(digitalSignageAction.updateDigitalSignageActions.failUpdateDigitalSignage({ error }));
            return caught;
        })
    ));

    deleteCampaign$ = createEffect(() => this.actions$.pipe(
        ofType(digitalSignageAction.deleteDigitalSignageActions.tryDeleteDigitalSignage),
        switchMap((id) => this.digitalSignageRequestService.deleteDigitalSignage(id)),
        map(() => digitalSignageAction.deleteDigitalSignageActions.successDeleteDigitalSignage()),
        tap(() => {
            this.router.navigate(['/home/digital-signage']);
        }),
        catchError((error: any, caught) => {
            this.store.dispatch(digitalSignageAction.deleteDigitalSignageActions.failDeleteDigitalSignage({ error }));
            return caught;
        })
    ));

    constructor(
        private store: Store,
        private router: Router,
        private actions$: Actions,
        private digitalSignageRequestService: DigitalSignageRequestService,


    ) { }
}
