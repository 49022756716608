import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { VersionRequestService } from 'src/app/pages/home/inner-pages/version/version-service/version-request.service';
import { lastVersionActions, } from './actions.version';
import { catchError, map, switchMap, tap } from 'rxjs';

@Injectable()
export class VersionEffect {

    versionList$ = createEffect(() => this.actions$.pipe(
        ofType(lastVersionActions.tryGetLastVersion),
        switchMap(({ versionType }) => this.versionRequestService.getLastVersion(versionType)),
        map((data) => lastVersionActions.successGetLastVersion({ lastVersion: data })),
        catchError((error: any, caught) => {
            this.store.dispatch(lastVersionActions.failGetLastVersion({ error }));
            return caught;
        })
    ));


    constructor(
        private store: Store,
        private actions$: Actions,
        private versionRequestService: VersionRequestService,
    ) { }
}