import { createFeature, createReducer, on } from '@ngrx/store';
import { countryListActions, getCountryDetailsActions } from './actions.country';

export interface CountryState {
    countryList: any;
    country: any;
    countryDetails: any;
}

const initialCountryState: CountryState = {
    countryList: undefined,
    country: undefined,
    countryDetails: undefined,
};

export const countryReducer = createFeature(
    {
        name: 'countryReducer',
        reducer: createReducer(
            initialCountryState,
            on(countryListActions.successGetCountryList,
                (state, { countryList }) => ({ ...state, countryList })),

            on(getCountryDetailsActions.successGetCountryDetails,
                (state, { countryDetails }) => ({ ...state, countryDetails })),
        )
    }
);
