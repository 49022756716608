import { Injectable } from '@angular/core';
import { CommonHeaders } from './common-headers';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { Observable, switchMap, tap } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SyncDetailsService extends CommonHeaders {

  constructor(
    protected override http: HttpClient,
    protected override store: Store,
  ) {
    super(http, store);
  }


  getSyncDetails(): Observable<any> {
    return this.getStrapiHeaders(true).pipe(
      switchMap(({ headers }) => this.http.get<any>(
        `${environment.strapiLinkApi}/sync-detail`,
        { headers }
      ))
    );
  }

}
