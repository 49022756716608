import { createFeature, createReducer, on } from '@ngrx/store';
import { updateLastActivityActions } from './actions.last-activity';


export interface LastActivity {
    lastActivityDetails: any;
}

const initialLastActivityDetails: LastActivity = {
    lastActivityDetails: undefined,
};

export const lastActivityReducer = createFeature(
    {
        name: 'LastActivityReducer',
        reducer: createReducer(
            initialLastActivityDetails,
            on(updateLastActivityActions.successUpdateLastActivityUser,
                (state, { lastActivityDetails }): LastActivity => ({ ...state, lastActivityDetails })
            ),
        )
    }
);