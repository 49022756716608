import { createFeature, createReducer, on } from '@ngrx/store';
import { getSyncDetailsActions } from './actions.sync';


export interface SyncState {
    syncDetails: any;
}

const initialSyncState: SyncState = {
    syncDetails: undefined,
};

export const syncReducer = createFeature(
    {
        name: 'syncReducer',
        reducer: createReducer(
            initialSyncState,
            on(getSyncDetailsActions.successGetSyncItem,
                (state, { syncDetails }) => {
                    return { ...state, syncDetails }
                }),
        )
    }
);