import { createActionGroup, emptyProps, props } from "@ngrx/store";

export const countryListActions = createActionGroup({
    source: 'Country',
    events: {
        'try get country list': props<{ pageIndex?: number, searchTerm?: any, filter?: any }>(),
        'success get country list': props<{ countryList: any }>(),
        'fail get country list': props<{ error: Error }>(),
    },
});

export const createCountryActions = createActionGroup({
    source: 'Country',
    events: {
        'try create country': emptyProps(),
        'success create country': props<{ countryList: any }>(),
        'fail create country': props<{ error: Error }>(),
    },
});

export const getCountryDetailsActions = createActionGroup({
    source: 'Country',
    events: {
        'try get Country details': props<{ countryId: any }>(),
        'success get Country details': props<{ countryDetails: any }>(),
        'fail get Country details': props<{ error: Error }>(),
    },
});
