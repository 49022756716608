import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { userHasRole } from 'src/common/common-business-methods';
import { UserRoleEnum } from '../../home/inner-pages/user-management/user.constant';

@Injectable({
  providedIn: 'root'
})
export class UserRedirectionService {

  constructor(private router: Router) { }



  redirectUserBasedOnRole(userRoles: any[]): void {
    if (userHasRole(userRoles, UserRoleEnum.SystemAdmin)) {
      this.router.navigate(['/']);
    } else if (userHasRole(userRoles, UserRoleEnum.ContentCreator) || userHasRole(userRoles, UserRoleEnum.ContentManager)) {
      this.router.navigate(['/home/campaign-management']);
    } else if (userHasRole(userRoles, UserRoleEnum.InfrastructureAdministrator)) {
      this.router.navigate(['/home/tv']);
    } else {
      this.router.navigate(['/unauthorized']);
    }
  }
}
