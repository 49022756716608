import { Component, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, map, skipWhile } from 'rxjs';
import { environment } from 'src/environments/environment';
import { selectUser } from 'src/features/users/selectors.users';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'TvScreenSaver';
  microsoftAuthSeen = false;

  currentUser$ = this.store.select(selectUser).pipe(
    skipWhile(response => !response),
    map(response => this.organizeCurrentUser(response))
  );


  constructor(
    private store: Store,
    private router: Router,
  ) { }

  ngOnInit(): void {

    (window as any).versionDate = environment.versionDate;

    const hrefLink = window.location.href;
    if (hrefLink.includes('/digital-signage-details') || hrefLink.includes('/tv-details') || hrefLink.includes('/user-management-details') || hrefLink.includes('/campaign-details')) {
      const currentUrl = window.location.href;
      localStorage.setItem('returnUrl', currentUrl);
    }


    this.router.events.pipe(filter((event: any) => event instanceof NavigationStart)).subscribe((data: NavigationStart) => {
      if (data.url.includes('/auth/microsoft') && !this.microsoftAuthSeen) {  // here && !this.microsoftAuthSeen
        this.microsoftAuthSeen = true;
        this.router.navigate(['/']);
      }
    });
  }


  organizeCurrentUser(user: any) {
    if (!user?.length) {
      return;
    }
    return user[0]

  }
}
