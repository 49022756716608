import { createFeature, createReducer, on } from '@ngrx/store';
import { userRoleListActions } from './action.userRole';
import { UserRoleList } from 'src/app/pages/home/inner-pages/user-management/user.constant';

export interface UserRolesState {
    userRolesList: UserRoleList[] | undefined;
}

const initialUserRolesState: UserRolesState = {
    userRolesList: undefined,

};


export const userRolesReducer = createFeature({
    name: 'userRoles',
    reducer: createReducer(
        initialUserRolesState,
        on(userRoleListActions.successGetUserRoleList, (state, { userRoleList }): UserRolesState => {
            return ({
                ...state,
                userRolesList: userRoleList,
            })
        }),
    ),
});
