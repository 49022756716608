import { createActionGroup, emptyProps, props } from "@ngrx/store";

export const campaignListActions = createActionGroup({
  source: 'list campaign',
  events: {
    'try get campaign list': props<{ userId?: any, pageIndex?: number, gridPageSize?: number, defaultCampaignExists: boolean, searchTerm?: string, filter?: any, allItem?: boolean }>(),
    'success get campaign list': props<{ campaignList: any }>(),
    'fail get campaign list': props<{ error: Error }>(),
  }
});

export const addCampaignActions = createActionGroup({
  source: 'add campaign',
  events: {
    'try add campaign': props<{ campaign: any }>(),
    'success add campaign': props<{ campaignDetails: any }>(),
    'fail add campaign': props<{ error: Error }>(),
  }
});

export const getCampaignDetailsActions = createActionGroup({
  source: 'get campaign',
  events: {
    'try get campaign details': props<{ campaignId: any }>(),
    'success get campaign details': props<{ campaignDetails: any }>(),
    'fail get campaign details': props<{ error: Error }>(),
  }
});

export const updateCampaignActions = createActionGroup({
  source: 'update campaign',
  events: {
    'try update campaign': props<{ campaignId: number; campaign: any; contentsToDelete: number[] }>(),
    'success update campaign': props<{ campaignDetails: any }>(),
    'fail update campaign': props<{ error: Error }>(),
  }
});

export const publishCampaignActions = createActionGroup({
  source: 'publish campaign',
  events: {
    'try publish campaign': props<{ campaign: any; publishStatus: boolean }>(),
    'success publish campaign': props<{ publishStatus: boolean }>(),
    'fail publish campaign': props<{ error: Error }>(),
  }
});

export const sendToAdminCampaignActions = createActionGroup({
  source: 'Send to admin campaign',
  events: {
    'try send to admin campaign': props<{ campaignId: any; }>(),
    'success send to admin campaign': emptyProps(),
    'fail send to admin campaign': props<{ error: Error }>(),
  }
});

export const createdByListActions = createActionGroup({
  source: 'list createdBy',
  events: {
    'try get list createdBy': emptyProps(),
    'success get list createdBy': props<{ createdByList: any }>(),
    'fail get list createdBy': props<{ error: Error }>(),
  }
});

export const setDefaultCampaignActions = createActionGroup({
  source: 'set default campaign',
  events: {
    'try set default campaign': props<{ campaignId: any }>(),
    'success set default campaign': emptyProps(),
    'fail set default campaign': props<{ error: Error }>(),
  }
});

export const deleteCampaignActions = createActionGroup({
  source: 'Campaign',
  events: {
    'try delete campaign': props<{ campaignId: number }>(),
    'success delete campaign': emptyProps(),
    'fail delete campaign': props<{ error: Error }>(),
  },
});


export const setNumberOfCampaignPerPage = createActionGroup({
  source: 'pagination',
  events: {
    'try set number of campaign': props<{ alternatingCampaignsPerPage: number }>(),
    'success set number of campaign': props<{ alternatingCampaignsPerPage: number }>(),
    'fail set number of campaign': props<{ error: Error }>(),
  }
});

export const setNumberOfPageIndex = createActionGroup({
  source: 'Page index',
  events: {
    'try set number of page index': props<{ numberOfPageIndex: number }>(),
    'success set number of page index': props<{ numberOfPageIndex: number }>(),
    'fail set number of page index': props<{ error: Error }>(),
  }
});

export const defaultCampaignActions = createActionGroup({
  source: 'default campaign',
  events: {
    'try get default campaign': emptyProps(),
    'success get default campaign': props<{ defaultCampaign: any }>(),
    'fail get default campaign': props<{ error: Error }>(),
  }
});

export const orderCampaignsActions = createActionGroup({
  source: 'order campaigns',
  events: {
    'try order campaigns': props<{ orderingContainer: any }>(),
    'success order campaigns': emptyProps(),
    'fail order campaign': props<{ error: Error }>(),
  }


});

export const orderContentsActions = createActionGroup({
  source: 'order contents',
  events: {
    'try order contents': props<{ orderingContainer: any }>(),
    'success order contents': emptyProps(),
    'fail order contents': props<{ error: Error }>(),
  }
});
