import { routerReducer } from "@ngrx/router-store";
import { authReducer } from "./auth/reducer.auth";
import { commonReducer } from "./common/reducer.common";
import { campaignReducer } from "./campaign/reducer.campaign";
import { networkReducer } from "./network/reducer.network";
import { tvReducer } from "./tv/reducer.tv";
import { roomReducer } from "./room/reducer.room";
import { countryReducer } from "./country/reducer.country";
import { userReducer } from "./users/reducer.users";
import { syncReducer } from "./sync/reducer.sync";
import { versionReducer } from "./version/reducer.version";
import { ReleaseNoteReducer } from "./release-note/reducer.release";
import { digitalSignageReducer } from "./digital-signage/reducer.digital";
import { lastActivityReducer } from "./last-activity-user/reducer.last-activity";
import { userRolesReducer } from "./user-roles/reducer.userRole";

export const reducers = {
    [authReducer.name]: authReducer.reducer,
    [campaignReducer.name]: campaignReducer.reducer,
    [userReducer.name]: userReducer.reducer,
    [tvReducer.name]: tvReducer.reducer,
    [roomReducer.name]: roomReducer.reducer,
    [countryReducer.name]: countryReducer.reducer,
    [networkReducer.name]: networkReducer.reducer,
    [commonReducer.name]: commonReducer.reducer,
    [syncReducer.name]: syncReducer.reducer,
    [versionReducer.name]: versionReducer.reducer,
    [ReleaseNoteReducer.name]: ReleaseNoteReducer.reducer,
    [digitalSignageReducer.name]: digitalSignageReducer.reducer,
    [lastActivityReducer.name]: lastActivityReducer.reducer,
    [userRolesReducer.name]: userRolesReducer.reducer,
    router: routerReducer
};