import { userReducer } from './reducer.users';

export const {
    selectUsersList,
    selectUser,
    selectUserDetails,
    selectAlternatingUsersPerPage,
    selectNumberOfUserPageIndex,
    selectSyncedUserInfo,
    selectSyncedUserPhoto,
    selectLastLoginDateForUser,
    selectLastCheckTimeUser
} = userReducer;
