import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import * as userAction from './actions.users';

import { Router } from '@angular/router';
import { catchError, filter, map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { UsersRequestService } from 'src/app/pages/home/inner-pages/user-management/user-management-services/users-request.service';
import { selectMicrosoftToken } from '../auth/selectors.auth';
import { customSendEmailActions } from '../network/actions.network';
import { updateLastActivityActions } from '../last-activity-user/actions.last-activity';
import { UserRedirectionService } from 'src/app/pages/login/services/user-redirection.service';



@Injectable()
export class UsersEffect {

    usersList$ = createEffect(() => this.actions$.pipe(
        ofType(userAction.usersListActions.tryGetUsersList),
        switchMap((page) => this.usersRequestService.getUsers(page)),
        map((data) => userAction.usersListActions.successGetUsersList({ usersList: data })),
        catchError((error: any, caught) => {
            this.store.dispatch(userAction.usersListActions.failGetUsersList({ error }));
            return caught;
        })
    ));

    user$ = createEffect(() => this.actions$.pipe(
        ofType(userAction.userActions.tryGetUser),
        switchMap(({ filter, doRedirect, displayName }) => this.usersRequestService.getUser({ filter }).pipe(
            map((user) => ({ user, doRedirect, displayName })),
        )),
        tap(({ user, doRedirect, displayName }) => {

            const _activeCurrentUser = user[0];
            this.store.dispatch(userAction.userActions.successGetUser({ user }));

            this.store.dispatch(userAction.syncUserPhotoActions.trySyncUserPhotoDetails());

            if (user?.find(x => x).id && _activeCurrentUser.isActive !== false) {
                this.store.dispatch(userAction.updateLastLoginForUserActions.tryUpdateLastLoginForUser({ userId: _activeCurrentUser.id, lastLoginDateForUser: new Date() }));
            }
            // user?.find()?.id
            if (!user?.find(x => x).displayName) {
                // this.store.dispatch(userAction.syncUserInfoActions.trySyncUserInfoDetails());
                this.store.dispatch(
                    userAction.updateSyncUserInfoActions.tryUpdateSyncUserInfoDetails({
                        userId: _activeCurrentUser.id,
                        displayName: displayName,
                    }));
            } else {
                this.store.dispatch(userAction.syncUserInfoActions.successSyncUserInfoDetails({ syncedUserInfo: _activeCurrentUser.displayName }));
            }

            /*
                in case of any redirection errors, make if (doRedirect) => if (doRedirect || user)
                and remove if (!user), consequently, any refresh will take the user to
                the /home/campaign-management path.
            */
            const _userHasDepartment = _activeCurrentUser.Department;
            const _userHasRoles = _activeCurrentUser.user_roles.length > 0;

            if (!_userHasDepartment && !_userHasRoles) { // When the unauthorized user tries to access system for the first time.
                this.store.dispatch(customSendEmailActions.trySendEmail({ user: _activeCurrentUser }));
            }

            if (doRedirect) {
                if (_userHasDepartment && _userHasRoles) {
                    this.userRedirectionService.redirectUserBasedOnRole(_activeCurrentUser?.user_roles);
                } else {
                    this.router.navigate(['/unauthorized']);
                }
            } else if (!user) {
                this.router.navigate(['/login']);
            }
        }),
        catchError((error: any, caught) => {
            this.store.dispatch(userAction.userActions.failGetUser({ error }));
            return caught;
        })
    ), {
        dispatch: false
    });

    userDetails$ = createEffect(() => this.actions$.pipe(
        ofType(userAction.getUserDetailsActions.tryGetUserDetails),

        switchMap(({ userId }) => this.usersRequestService.getUsersDetails(userId)),
        tap((data) => {
            if (!data) {
                this.router.navigate(['/']);
            }
        }),
        filter(data => !!data),
        map((data) => userAction.getUserDetailsActions.successGetUserDetails({ userDetails: { data } })),
        catchError((error: any, caught) => {
            this.store.dispatch(userAction.getUserDetailsActions.failGetUserDetails({ error }));
            return caught;
        })
    ));

    updateUser$ = createEffect(() => this.actions$.pipe(
        ofType(userAction.updateUserActions.tryUpdateUser),
        switchMap(({ userId, user, data }) => this.usersRequestService.updateUser(userId, user, data)),
        tap((data) => {
            // console.log(data, 'update');

        }),
        map((data) => userAction.updateUserActions.successUpdateUser({ userDetails: { data } })),
        catchError((error: any, caught) => {
            this.store.dispatch(userAction.updateUserActions.failUpdateUser({ error }));
            return caught;
        })
    ));

    updateUserIsActive$ = createEffect(() => this.actions$.pipe(
        ofType(userAction.updateUserIsActiveActions.tryUpdateIsActiveUser),
        switchMap(({ userId, status, }) => this.usersRequestService.updateUserIsActive(userId, status)),
        map((data) => userAction.updateUserIsActiveActions.successUpdateIsActiveUser({ userDetails: { data } })),
        catchError((error: any, caught) => {
            this.store.dispatch(userAction.updateUserIsActiveActions.failUpdateIsActiveUser({ error }));
            return caught;
        })
    ));

    // Start Sync User Info
    syncUserInfo$ = createEffect(() => this.actions$.pipe(
        ofType(userAction.syncUserInfoActions.trySyncUserInfoDetails),
        switchMap(() => this.usersRequestService.getSyncUserInfo()),
        // tap((data) => {
        //     console.log(data);
        // }),
        map((data) => userAction.syncUserInfoActions.successSyncUserInfoDetails({ syncedUserInfo: data.displayName })),
        catchError((error: any, caught) => {
            this.store.dispatch(userAction.syncUserInfoActions.failSyncUserInfoDetails({ error }));
            return caught;
        })
    ));

    updateSyncUserInfo$ = createEffect(() => this.actions$.pipe(
        ofType(userAction.updateSyncUserInfoActions.tryUpdateSyncUserInfoDetails),
        switchMap(({ userId, displayName }) => this.usersRequestService.updateSyncUserInfo(userId, displayName)),
        catchError((error: any, caught) => {
            this.store.dispatch(userAction.syncUserInfoActions.failSyncUserInfoDetails({ error }));
            return caught;
        })
    ), { dispatch: false });

    // Start Sync User Photo
    syncUserPhoto$ = createEffect(() => this.actions$.pipe(
        ofType(userAction.syncUserPhotoActions.trySyncUserPhotoDetails),
        withLatestFrom(this.store.select(selectMicrosoftToken)),
        switchMap(([_, tokenObject]) => this.usersRequestService.getSyncUserPhoto(tokenObject.accessToken)),
        switchMap((dataObservable) =>
            dataObservable.pipe(
                take(1),
                map((base64data) => userAction.syncUserPhotoActions.successSyncUserPhotoDetails({ syncedUserPhoto: base64data }))
            )
        ),
        catchError((error: any, caught) => {
            this.store.dispatch(userAction.syncUserPhotoActions.failSyncUserPhotoDetails({ error }));
            return caught;
        })
    ));

    updateSyncUserPhoto$ = createEffect(() => this.actions$.pipe(
        ofType(userAction.updateSyncUserPhotoActions.tryUpdateSyncUserPhotoDetails),
        switchMap(({ userId, profilePictureId }) => this.usersRequestService.updateSyncUserPhoto(userId, profilePictureId)),
        catchError((error: any, caught) => {
            this.store.dispatch(userAction.syncUserInfoActions.failSyncUserInfoDetails({ error }));
            return caught;
        })
    ), { dispatch: false });

    updateUserLastLoginDate$ = createEffect(() => this.actions$.pipe(
        ofType(userAction.updateLastLoginForUserActions.tryUpdateLastLoginForUser),
        switchMap(({ userId, lastLoginDateForUser }) => this.usersRequestService.updateLastLoginUser(userId, lastLoginDateForUser)),
        catchError((error: any, caught) => {
            this.store.dispatch(userAction.updateLastLoginForUserActions.failUpdateLastLoginForUser({ error }));
            return caught;
        })
    ), { dispatch: false });

    getLastCheckTimeUserAction$ = createEffect(() => this.actions$.pipe(
        ofType(userAction.lastCheckTimeUserActions.tryGetLastCheckTimeUserItem),
        switchMap(() => this.usersRequestService.getLastCheckTimeUser()),
        map(({ data }) => userAction.lastCheckTimeUserActions.successGetLastCheckTimeUserItem({ lastCheckTimeUser: data })),
        catchError((error: any, caught) => {
            this.store.dispatch(userAction.lastCheckTimeUserActions.failGetLastCheckTimeUserItem({ error }));
            return caught;
        })
    ));

    updateLastCheckTimeUserAction$ = createEffect(() => this.actions$.pipe(
        ofType(userAction.updateLastCheckTimeUserActions.tryUpdateLastCheckTimeUserItem),
        switchMap(() => this.usersRequestService.updateLastCheckTimeUser()),
        map(({ data }) => userAction.updateLastCheckTimeUserActions.successUpdateLastCheckTimeUserItem({ lastCheckTimeUser: data })),
        catchError((error: any, caught) => {
            this.store.dispatch(userAction.updateLastCheckTimeUserActions.failUpdateLastCheckTimeUserItem({ error }));
            return caught;
        })
    ));

    updateUserStatusActive$ = createEffect(() => this.actions$.pipe(
        ofType(userAction.updateUserStatusActiveActions.tryUpdateStatusActiveUser),
        switchMap(({ userId, statusActive }) => this.usersRequestService.updateUserStatusActive(userId, statusActive)),
        map((data) => userAction.updateUserStatusActiveActions.successUpdateStatusActiveUser({ userDetails: { data } })),
        catchError((error: any, caught) => {
            this.store.dispatch(userAction.updateUserStatusActiveActions.failUpdateStatusActiveUser({ error }));
            return caught;
        })
    ));

    constructor(
        private store: Store,
        private actions$: Actions,
        private usersRequestService: UsersRequestService,
        private router: Router,
        private userRedirectionService: UserRedirectionService

    ) { }
}
