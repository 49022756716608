import { createFeature, createReducer, on } from '@ngrx/store';
import * as userAction from './actions.users';
import { AppConstants } from 'src/common/constant';



export interface UserState {
    usersList: any;
    user: any;
    userDetails: any;
    alternatingUsersPerPage: number;
    numberOfUserPageIndex: number;
    syncedUserInfo: any,
    syncedUserPhoto: any,
    lastLoginDateForUser: any,
    lastCheckTimeUser: any
}

const initialUserState: UserState = {
    usersList: undefined,
    user: undefined,
    userDetails: undefined,
    alternatingUsersPerPage: 5,
    numberOfUserPageIndex: 1,
    syncedUserInfo: undefined,
    syncedUserPhoto: undefined,
    lastLoginDateForUser: undefined,
    lastCheckTimeUser: undefined
};

export const userReducer = createFeature(
    {
        name: 'userReducer',
        reducer: createReducer(
            initialUserState,
            on(userAction.usersListActions.successGetUsersList,
                (state, { usersList }): UserState => {
                    return { ...state, usersList }
                }),

            on(userAction.userActions.successGetUser,
                (state, { user }): UserState => ({ ...state, user })),

            on(userAction.getUserDetailsActions.successGetUserDetails,
                (state, { userDetails }): UserState => {
                    debugger
                    return ({ ...state, userDetails })
                }),

            on(userAction.setNumberOfUserPerPage.setNumberOfUser,
                (state, { alternatingUsersPerPage }): UserState => {
                    return { ...state, alternatingUsersPerPage }
                }),

            on(userAction.setNumberOfUserPageIndex.setNumberOfUserPageIndex,
                (state, { numberOfUserPageIndex }): UserState => {
                    return { ...state, numberOfUserPageIndex }
                }),

            on(userAction.updateUserActions.successUpdateUser,
                (state, { userDetails }): UserState => ({ ...state, userDetails })),

            on(userAction.updateUserIsActiveActions.successUpdateIsActiveUser,
                (state, { userDetails }): UserState => ({ ...state, userDetails })),

            on(userAction.syncUserInfoActions.successSyncUserInfoDetails,
                (state, { syncedUserInfo }): UserState => ({ ...state, syncedUserInfo })),

            on(userAction.syncUserPhotoActions.successSyncUserPhotoDetails,
                (state, { syncedUserPhoto }): UserState => ({ ...state, syncedUserPhoto })),

            on(userAction.syncUserPhotoActions.failSyncUserPhotoDetails,
                (state, { error }): UserState => ({ ...state, syncedUserPhoto: AppConstants.defaultImage })),

            on(userAction.updateLastLoginForUserActions.successUpdateLastLoginForUser,
                (state, { userDetails }): UserState => ({ ...state, userDetails })),

            on(userAction.lastCheckTimeUserActions.successGetLastCheckTimeUserItem,
                (state, { lastCheckTimeUser }): UserState => ({ ...state, lastCheckTimeUser })),

            on(userAction.updateLastCheckTimeUserActions.successUpdateLastCheckTimeUserItem,
                (state, { lastCheckTimeUser }): UserState => ({ ...state, lastCheckTimeUser })),

            on(userAction.updateUserStatusActiveActions.successUpdateStatusActiveUser,
                (state, { userDetails }): UserState => ({ ...state, userDetails })),
        )
    }
);