import { createFeature, createReducer, on } from '@ngrx/store';
import { roomListActions, getRoomDetailsActions, syncRoomsActions } from './actions.room';

export interface RoomState {
    roomList: any;
    room: any;
    roomDetails: any;
    syncedRoomList: any;
}

const initialRoomState: RoomState = {
    roomList: undefined,
    room: undefined,
    roomDetails: undefined,
    syncedRoomList: undefined
};

export const roomReducer = createFeature(
    {
        name: 'roomReducer',
        reducer: createReducer(
            initialRoomState,
            on(roomListActions.successGetRoomList,
                (state, { roomList }) => ({ ...state, roomList })),

            on(getRoomDetailsActions.successGetRoomDetails,
                (state, { roomDetails }) => ({ ...state, roomDetails })),

            on(syncRoomsActions.successSyncRoomsDetails,
                (state, { syncedRoomList }) => ({ ...state, syncedRoomList })),
        )
    }
);
