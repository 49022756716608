import { environment } from "src/environments/environment";
import { AppConstants } from "src/common/constant";
import * as moment from "moment";

export const thrower = (message: string) => {
  throw Error(message);
};

export const uniqueValues = (value, index, array) => {
  return array.indexOf(value) === index;
};

export const extractUsername = (email) => {
  if (!email || email.trim() === '') {
    return '';
  }

  const atIndex = email.indexOf('@');
  const username = atIndex !== -1 ? email.substring(0, atIndex) : email;
  return username;
}

export const distinctObjectByProperty = (arr, prop) => {
  const uniqueValues = new Set();
  return arr.filter(obj => {
    const value = obj[prop];
    if (!uniqueValues.has(value)) {
      uniqueValues.add(value);
      return true;
    }
    return false;
  });
}


export const imageSelected = (item: any) => {
  let srcImage = '';
  if (item?.profilePicture?.url) {
    srcImage = environment.strapiLink + item?.profilePicture?.url
  } else {
    srcImage = AppConstants.defaultImage;
  }
  return srcImage
}


export function filterDefaultValues(newValuesObj: any): boolean {
  const defaultValues = {
    createdBy: [],
    published: 'true',
    roomSelected: false,
    tvLocation: [],
    live: undefined,
    startDate: "Invalid date",
    endDate: "Invalid date",
    currentDate: moment(new Date).format('YYYY-MM-DD')
  };


  if (
    (defaultValues['createdBy'].length === newValuesObj['createdBy'].length) &&
    (defaultValues['published'] === newValuesObj['published']) &&
    (defaultValues['roomSelected'] === newValuesObj['roomSelected']) &&
    (defaultValues['tvLocation'].length === newValuesObj['tvLocation'].length) &&
    (defaultValues['live'] === newValuesObj['live']) &&
    (defaultValues['startDate'] === newValuesObj['startDate']) &&
    defaultValues['endDate'] === newValuesObj['endDate'] &&
    defaultValues['currentDate'] === newValuesObj['currentDate']
  ) {
    return true
  }

  return false

}