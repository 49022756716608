import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { SyncDetailsService } from 'src/app/pages/shared/services/sync-details.service';
import { getSyncDetailsActions } from './actions.sync';

@Injectable()
export class SyncEffect {

    syncDetailsActions$  = createEffect(() => this.actions$.pipe(
        ofType(getSyncDetailsActions.tryGetSyncItem),
        switchMap(() => this.syncDetailsService.getSyncDetails()),
        map(({ data }) => getSyncDetailsActions.successGetSyncItem({ syncDetails: data })),
        catchError((error: any, caught) => {
            this.store.dispatch(getSyncDetailsActions.failGetSyncItem({ error }));
            return caught;
        })
    ));

    constructor(
        private store: Store,
        private actions$: Actions,
        private syncDetailsService: SyncDetailsService,
    ) { }
}