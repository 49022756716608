import { createFeature, createReducer, on } from '@ngrx/store';
import { networkListActions, getNetworkDetailsActions, setNumberOfNetworkPerPage, setNumberOfNetworkPageIndex } from './actions.network';
import { AppConstants } from 'src/common/constant';

export interface NetworkState {
    networkList: any;
    network: any;
    networkDetails: any;
    alternatingNetworksPerPage: number;
    numberOfNetworkPageIndex: number;
}

const initialNetworkState: NetworkState = {
    networkList: undefined,
    network: undefined,
    networkDetails: undefined,
    alternatingNetworksPerPage: AppConstants.gridPageSize?.size,
    numberOfNetworkPageIndex: 1,
};

export const networkReducer = createFeature(
    {
        name: 'networkReducer',
        reducer: createReducer(
            initialNetworkState,
            on(networkListActions.successGetNetworkList,
                (state, { networkList }) => ({ ...state, networkList })),

            on(setNumberOfNetworkPerPage.trySetNumberOfNetwork,
                (state, { alternatingNetworksPerPage }) => ({ ...state, alternatingNetworksPerPage })),

            on(setNumberOfNetworkPageIndex.trySetNumberOfPageIndex,
                (state, { numberOfNetworkPageIndex }) => ({ ...state, numberOfNetworkPageIndex })),

            on(getNetworkDetailsActions.successGetNetworkDetails,
                (state, { networkDetails }) => ({ ...state, networkDetails })),
        )
    }
);
