import { createFeature, createReducer, on } from '@ngrx/store';
import * as CommonAction from './actions.common';

export interface CommonState {
}

const initialCommonState: CommonState = {
};

export const commonReducer = createFeature(
    {
        name: 'commonReducer',
        reducer: createReducer(
            initialCommonState
        )
    }
);
