import { createActionGroup, emptyProps, props } from "@ngrx/store";


export const getSyncDetailsActions = createActionGroup({
    source: 'Sync',
    events: {
        'try get sync item': emptyProps(),
        'success get sync item': props<{ syncDetails: any }>(),
        'fail get sync item': props<{ error: Error }>(),
    },
});

