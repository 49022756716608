import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, switchMap } from 'rxjs';
import { CommonHeaders } from 'src/app/pages/shared/services/common-headers';
import { AppConstants } from 'src/common/constant';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TVRequestService extends CommonHeaders {

  constructor(
    protected override http: HttpClient,
    protected override store: Store,
  ) {
    super(http, store);
  }

  getTvs(criteria): Observable<any> {
    const searchTerm = criteria?.searchTerm ?? '';
    const filter = criteria?.filter ?? '';
    let finalPageSize = criteria?.gridPageSize ?? AppConstants.gridPageSize.size;
    let filters = '';
    if (filter) {
      if (filter.roomListSelected?.length > 0) {
        filter.roomListSelected.forEach((roomId, index) => {
          filters += `&filters[$and][0][$or][${index}][room][id][$eq]=${roomId}`;
        });
      }
      if (filter.status?.length > 0) {
        filter.status.forEach((status, index) => {
          filters += `&filters[$and][1][status][$eq]=${status.key}`;
        });
      }
      if (filter.screenSaverStatus?.length > 0) {
        filter.screenSaverStatus.forEach((status, index) => {
          filters += `&filters[$and][1][activeStatus][$eq]=${status.key}`;
        });
      }
    }

    let apiLink = `tvs?populate=*&pagination[pageSize]=${finalPageSize}&pagination[page]=${criteria.pageIndex ?? 1}&filters[$and][0][name][$containsi]=${searchTerm}${filters}`;

    if (criteria.allItem) {
      apiLink = `tvs?populate=*${filters}`
    }

    return this.getStrapiHeaders(true).pipe(
      switchMap(({ headers }) => this.http.get<any>(
        `${environment.strapiLinkApi}/${apiLink}`,
        { headers }
      ))
    );
  }

  getTvDetails(id: number): Observable<any> {
    return this.getStrapiHeaders(true).pipe(
      switchMap(({ headers }) => this.http.get<any>(
        `${environment.strapiLinkApi}/tvs/${id}?populate=*`,
        { headers }
      ))
    );
  }

  updateTv(tvId, tv, status): Observable<any> {

    tv = {
      data: {
        ...tv,
        status
      }
    };
    return this.getStrapiHeaders(true).pipe(
      switchMap(({ headers }) => this.http.put<any>(
        `${environment.strapiLinkApi}/tvs/${tvId}`,
        tv,
        { headers }
      ))
    );
  }

}
