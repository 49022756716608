import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import * as commonAction from './actions.common';
import { CommonService } from 'src/app/pages/shared/services/common.service';

@Injectable()
export class FileEffect {


  uploadFile$ = createEffect(() => this.actions$.pipe(
    ofType(commonAction.uploadFileActions.tryUploadFile),
    switchMap(({ campaignContent }) => this.commonService.uploadFile(campaignContent)),
    // tap(({ campaignContent }) => {
    //   debugger
    // }),
    map(({ response, campaignContent }) => commonAction.uploadFileActions.addFileToCampaignForm({ response, campaignContent })),
    catchError((error: any, caught) => {
      this.store.dispatch(commonAction.uploadFileActions.failUploadFile({ error }));
      return caught;
    })
  ));

  uploadUserImageFile$ = createEffect(() => this.actions$.pipe(
    ofType(commonAction.uploadUserImageFileActions.tryUploadUserImageFile),
    switchMap(({ userImageContent }) => this.commonService.uploadFile(userImageContent)),
    // tap(({ data }) => {
    //   debugger
    // }),
    map(({ response, userImageContent }) => commonAction.uploadUserImageFileActions.updateUserImageFileOnStrapi({ response, userImageContent })),
    catchError((error: any, caught) => {
      this.store.dispatch(commonAction.uploadUserImageFileActions.failUploadUserImageFile({ error }));
      return caught;
    })
  ));

  deleteFile$ = createEffect(() => this.actions$.pipe(
    ofType(commonAction.deleteFileActions.tryDeleteFile),
    switchMap(({ id }) => this.commonService.deleteFile(id)),
    tap(({ id }) => {
      console.log(id);
    }),
    map(({ id }) => commonAction.deleteFileActions.successDeleteFile({ id })),
    catchError((error: any, caught) => {
      this.store.dispatch(commonAction.deleteFileActions.failDeleteFile({ error }));
      return caught;
    })
  ));

  constructor(
    private store: Store,
    private actions$: Actions,
    private commonService: CommonService,

  ) { }
}
