import { createFeature, createReducer, on } from '@ngrx/store';
import * as tvAction from './actions.tv';
import { AppConstants } from 'src/common/constant';


export interface TvState {
    tvList: any;
    tv: any;
    numberOfTvsPerPage: number;
    numberOfTvPageIndex: number;
    tvDetails: any;
}

const initialTvState: TvState = {
    tvList: undefined,
    tv: undefined,
    tvDetails: undefined,
    numberOfTvsPerPage: AppConstants.gridPageSize?.size,
    numberOfTvPageIndex: 1,
};

export const tvReducer = createFeature(
    {
        name: 'tvReducer',
        reducer: createReducer(
            initialTvState,
            on(tvAction.tvListActions.successGetTvList,
                (state, { tvList }) => ({ ...state, tvList })),

            on(tvAction.getTvDetailsActions.successGetTvDetails,
                (state, { tvDetails }) => ({ ...state, tvDetails })),

            on(tvAction.setNumberOfTvsPerPage.trySetNumberOfTvs,
                (state, { numberOfTvsPerPage }) => ({ ...state, numberOfTvsPerPage })),

            on(tvAction.setNumberOfTvPageIndex.trySetNumberOfTvPageIndex,
                (state, { numberOfTvPageIndex }) => ({ ...state, numberOfTvPageIndex })),

            on(tvAction.updateTvActions.successUpdateTv,
                (state, { tvDetails }) => ({ ...state, tvDetails })),
        )
    }
);
