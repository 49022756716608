import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, switchMap } from 'rxjs';
import { CommonHeaders } from 'src/app/pages/shared/services/common-headers';
import { AppConstants } from 'src/common/constant';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CampaignRequestService extends CommonHeaders {

  constructor(
    protected override http: HttpClient,
    protected override store: Store,
  ) {
    super(http, store);
  }

  getCampaigns(criteria: any): Observable<any> {
    const searchTerm = criteria?.searchTerm ?? '';
    const filter = criteria?.filter ?? '';
    let finalPageSize = criteria?.gridPageSize ?? AppConstants.gridPageSize.size;
    if (criteria.defaultCampaignExists) {
      finalPageSize--;
    }

    let filters = `&filters[isDefault]=false`;
    if (filter) {
      if (filter.published) {
        finalPageSize = 1000; // in case of filter.published is 'true' or 'false' the campaign will show with no pagination 
        filters += `&filters[$and][0][published]=${filter.published === 'true'}`;// [$and][0] may be not necessary
      }

      if (filter.startDate && filter.startDate != 'Invalid date') {
        filters += `&filters[$and][0][startDate][$gte]=${filter.startDate}`;
      }

      if (filter.endDate && filter.endDate != 'Invalid date') {
        filters += `&filters[$and][0][endDate][$lte]=${filter.endDate}`;
      }

      if (filter.createdBy?.length > 0) {
        filter.createdBy.forEach((createdByValue, index) => {
          filters += `&filters[$and][1][$or][${index}][user]=${createdByValue.userId}`
        });
      }

      if (filter.tvLocation?.length > 0) {
        filter.tvLocation.forEach((tvLocationValue, index) => {
          filters += `&filters[$and][0][$or][${index}][tvs]=${tvLocationValue}`;
        });
      } else {
        if (filter.roomSelected) {
          filters += `&filters[$and][0][$or][${0}][tvs]=${0}`;
        }

      }

      if (filter.live === true) {
        if (!(filter.published === 'true')) {
          filters += `&filters[$and][0][published]=true`; // [$and][0] may be not necessary
        }
        filters += `&filters[$or][0][startDate][$null]=true` +
          `&filters[$or][1][$and][0][startDate][$lte]=${filter.currentDate}&filters[$or][1][$and][1][endDate][$gte]=${filter.currentDate}`
      }

      if (filter.live === false) {//not live
        filters += `&filters[$and][0][startDate][$null]=false` +
          `&filters[$and][0][$or][0][$and][0][startDate][$gt]=${filter.currentDate}` +
          `&filters[$and][0][$or][0][$and][0][endDate][$gt]=${filter.currentDate}` +
          `&filters[$and][0][$or][1][$and][0][startDate][$lt]=${filter.currentDate}` +
          `&filters[$and][0][$or][1][$and][0][endDate][$lt]=${filter.currentDate}` +
          `&filters[$and][0][published]=true`
          ;
      }
    }

    if (criteria.userId) {
      filters += `&filters[user]=${criteria.userId}`;
    }

    let apiLink = `campaigns?populate=*&pagination[start]=${finalPageSize * criteria.pageIndex - finalPageSize}&pagination[limit]=${finalPageSize}&filters[$and][0][name][$containsi]=${searchTerm}${filters}`

    apiLink += '&sort[0]=createdAt:desc';

    if (criteria.allItem) {
      apiLink = `campaigns?populate=*${filters}`
    }


    return this.getStrapiHeaders(true).pipe(
      switchMap(({ headers }) => this.http.get<any>(
        `${environment.strapiLinkApi}/${apiLink}`,
        { headers }
      ))
    );
  }

  getCreatedByList(): Observable<any> {
    return this.getStrapiHeaders(true).pipe(
      switchMap(({ headers }) => this.http.get<any>(
        `${environment.strapiLinkApi}/campaigns?populate=*`, // here => get user attribute only
        { headers }
      ))
    );
  }

  getCampaignDetails(id: number): Observable<any> {
    return this.getStrapiHeaders(true).pipe(
      switchMap(({ headers }) => this.http.get<any>(
        `${environment.strapiLinkApi}/campaigns/${id}?populate[user][populate][0]=user_roles&populate[tvs]=*&populate[contents]=*`,
        { headers }
      ))
    );
  }

  addCampaign(campaign): Observable<any> {

    campaign = {
      data: {
        ...campaign
      }
    };

    return this.getStrapiHeaders(true).pipe(
      switchMap(({ headers }) => this.http.post<any>(
        `${environment.strapiLinkApi}/campaigns`,
        campaign,
        { headers }
      ))
    );
  }

  updateCampaign(campaignId, campaign, contentsToDelete): Observable<any> {
    campaign = {
      data: {
        ...campaign,
        contentsToDelete
      }
    };

    return this.getStrapiHeaders(true).pipe(
      switchMap(({ headers }) => this.http.put<any>(
        `${environment.strapiLinkApi}/campaigns/${campaignId}`,
        campaign,
        { headers }
      ))
    );
  }

  deleteCampaign({ campaignId }): Observable<any> {
    return this.getStrapiHeaders(true).pipe(
      switchMap(({ headers }) => this.http.delete<any>(
        `${environment.strapiLinkApi}/campaigns/${campaignId}`,
        { headers }
      ))
    );
  }

  publishCampaign(campaign: any, publishStatus: boolean): Observable<any> {
    campaign = {
      data: {
        ...campaign,
        published: publishStatus
      }
    };
    return this.getStrapiHeaders(true).pipe(
      switchMap(({ headers }) => this.http.put<any>(
        `${environment.strapiLinkApi}/publish`,
        campaign,
        { headers }
      ))
    );
  }

  sentToAdminCampaign(campaignId: any): Observable<any> {
    const campaign = {
      data: {
        campaignId
      }
    };

    return this.getStrapiHeaders(true).pipe(
      switchMap(({ headers }) => this.http.put<any>(
        `${environment.strapiLinkApi}/sendToAdmin`,
        campaign,
        { headers }
      ))
    );
  }

  getDefaultCampaign(): Observable<any> {
    return this.getStrapiHeaders(true).pipe(
      switchMap(({ headers }) => this.http.get<any>(
        `${environment.strapiLinkApi}/campaigns?populate=*&filters[isDefault]=true`,
        { headers }
      ))
    );
  }

  orderCampaigns(orderingContainer): Observable<any> {
    return this.getStrapiHeaders(true).pipe(
      switchMap(({ headers }) => this.http.put<any>(
        `${environment.strapiLinkApi}/orderCampaigns`,
        orderingContainer,
        { headers }
      ))
    );
  }

  orderContents(orderingContainer): Observable<any> {
    return this.getStrapiHeaders(true).pipe(
      switchMap(({ headers }) => this.http.put<any>(
        `${environment.strapiLinkApi}/orderContents`,
        orderingContainer,
        { headers }
      ))
    );
  }
}
