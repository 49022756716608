import { createActionGroup, emptyProps, props } from "@ngrx/store";


export const usersListActions = createActionGroup({
    source: 'Users',
    events: {
        'try get users list': props<{ pageIndex?: number, gridPageSize?: number, searchTerm?: any, filter?: any, allItem?: boolean }>(),
        'success get users list': props<{ usersList: any }>(),
        'fail get users list': props<{ error: Error }>(),
    },
});


export const userActions = createActionGroup({
    source: 'User',
    events: {
        'try get user': props<{ filter?: any, doRedirect?: boolean, displayName?: string }>(),
        'success get user': props<{ user: any }>(),
        'fail get user': props<{ error: Error }>(),
    },
});

export const getUserDetailsActions = createActionGroup({
    source: 'Users',
    events: {
        'try get user details': props<{ userId: any }>(),
        'success get user details': props<{ userDetails: any }>(),
        'fail get user details': props<{ error: Error }>(),
    },
});


export const updateUserActions = createActionGroup({
    source: 'User',
    events: {
        'try update user': props<{ userId: number; user: any; data?: any }>(),
        'success update user': props<{ userDetails: any }>(),
        'fail update user': props<{ error: Error }>(),
    }
});

export const updateUserIsActiveActions = createActionGroup({
    source: 'User Is Active',
    events: {
        'try update is active user': props<{ userId: number; status: boolean; }>(),
        'success update is active user': props<{ userDetails: any }>(),
        'fail update is active user': props<{ error: Error }>(),
    }
});

export const setNumberOfUserPerPage = createActionGroup({
    source: 'pagination',
    events: {
        'set number of user': props<{ alternatingUsersPerPage: number }>(),
    }
});

export const setNumberOfUserPageIndex = createActionGroup({
    source: 'User Page index',
    events: {
        'set number of user page index': props<{ numberOfUserPageIndex: number }>(),
    }
});

export const syncUserInfoActions = createActionGroup({
    source: 'Sync User Info',
    events: {
        'try sync User Info details': emptyProps(),
        'success sync User Info details': props<{ syncedUserInfo: any }>(),
        'fail sync User Info details': props<{ error: Error }>(),
    },
});

export const updateSyncUserInfoActions = createActionGroup({
    source: 'Update Sync Info',
    events: {
        'try update sync User Info details': props<{ userId, displayName }>(),
        'success update sync User Info details': emptyProps(),
        'fail update sync User Info details': props<{ error: Error }>(),
    },
});

export const syncUserPhotoActions = createActionGroup({
    source: 'Sync User Photo',
    events: {
        'try sync User Photo details': emptyProps(),
        'success sync User Photo details': props<{ syncedUserPhoto: any }>(),
        'fail sync User Photo details': props<{ error: Error }>(),
    },
});

export const updateSyncUserPhotoActions = createActionGroup({
    source: 'Update Sync Photo',
    events: {
        'try update sync User Photo details': props<{ userId, profilePictureId }>(),
        'success update sync User Photo details': emptyProps(),
        'fail update sync User Photo details': props<{ error: Error }>(),
    },
});

export const updateLastLoginForUserActions = createActionGroup({
    source: 'User Last Login',
    events: {
        'try update last login for user': props<{ userId: number; lastLoginDateForUser: Date; }>(),
        'success update last login for user': props<{ userDetails: any }>(),
        'fail update last login for user': props<{ error: Error }>(),
    }
});

export const lastCheckTimeUserActions = createActionGroup({
    source: 'Last Check Time User',
    events: {
        'try get LastCheckTimeUser item': emptyProps(),
        'success get LastCheckTimeUser item': props<{ lastCheckTimeUser: any }>(),
        'fail get LastCheckTimeUser item': props<{ error: Error }>(),
    },
});

export const updateLastCheckTimeUserActions = createActionGroup({
    source: 'Last Check Time User',
    events: {
        'try update LastCheckTimeUser item': emptyProps(),
        'success update LastCheckTimeUser item': props<{ lastCheckTimeUser: any }>(),
        'fail update LastCheckTimeUser item': props<{ error: Error }>(),
    },
});

export const updateUserStatusActiveActions = createActionGroup({
    source: 'User Status Active',
    events: {
        'try update status active user': props<{ userId: number; statusActive: string; }>(),
        'success update status active user': props<{ userDetails: any }>(),
        'fail update status active user': props<{ error: Error }>(),
    }
});