import { createActionGroup, emptyProps, props } from "@ngrx/store";

export const roomListActions = createActionGroup({
    source: 'Room',
    events: {
        'try get room list': props<{ pageIndex?: number, searchTerm?: any, filter?: any}>(),
        'success get room list': props<{ roomList: any }>(),
        'fail get room list': props<{ error: Error }>(),
    },
});

export const createRoomsActions = createActionGroup({
    source: 'Room',
    events: {
        'try create rooms': props<{ roomsList: any }>(),
        'success create rooms': emptyProps(),
        'fail create rooms': props<{ error: Error }>(),
    },
});

export const getRoomDetailsActions = createActionGroup({
    source: 'Room',
    events: {
        'try get Room details': props<{ roomId: any }>(),
        'success get Room details': props<{ roomDetails: any }>(),
        'fail get Room details': props<{ error: Error }>(),
    },
});

export const syncRoomsActions = createActionGroup({
    source: 'Room',
    events: {
        'try sync Rooms details': emptyProps(),
        'success sync Rooms details': props<{ syncedRoomList: any }>(),
        'fail sync Rooms details': props<{ error: Error }>(),
    },
});
