import { createActionGroup, emptyProps, props } from "@ngrx/store";

export const networkListActions = createActionGroup({
    source: 'Network',
    events: {
        'try get network list': props<{ pageIndex: number, gridPageSize?: number, searchTerm?: any }>(),
        'success get network list': props<{ networkList: any }>(),
        'fail get network list': props<{ error: Error }>(),
    },
});

export const addNetworkActions = createActionGroup({
    source: 'Network',
    events: {
        'try add network': props<{ network: any }>(),
        'success add network': emptyProps(),
        'fail add network': props<{ error: Error }>(),
    },
});

export const getNetworkDetailsActions = createActionGroup({
    source: 'Network',
    events: {
        'try get network details': props<{ networkId: any, gridPageSize?: number, }>(),
        'success get network details': props<{ networkDetails: any }>(),
        'fail get network details': props<{ error: Error }>(),
    },
});


export const updateNetworkActions = createActionGroup({
    source: 'Network',
    events: {
        'try update network': props<{ network: any }>(),
        'success update network': emptyProps(),
        'fail update network': props<{ error: Error }>(),
    },
});


export const deleteNetworkActions = createActionGroup({
    source: 'Network',
    events: {
        'try delete network': props<{ networkId: number }>(),
        'success delete network': emptyProps(),
        'fail delete network': props<{ error: Error }>(),
    },
});


export const setNumberOfNetworkPerPage = createActionGroup({
    source: 'pagination',
    events: {
      'try set number of network': props<{ alternatingNetworksPerPage: number }>(),
      'success set number of network': props<{ alternatingNetworksPerPage: number }>(),
      'fail set number of network': props<{ error: Error }>(),
    }
  });
  
  export const setNumberOfNetworkPageIndex = createActionGroup({
    source: 'Page index',
    events: {
      'try set number of page index': props<{ numberOfNetworkPageIndex: number }>(),
      'success set number of page index': props<{ numberOfNetworkPageIndex: number }>(),
      'fail set number of page index': props<{ error: Error }>(),
    }
  });

  export const customSendEmailActions = createActionGroup({
    source: 'Send Email',
    events: {
        'try send email': props<{ user: any }>(),
        'success send email': emptyProps(),
        'fail send email': props<{ error: Error }>(),
    },
});