import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, catchError, tap, throwError } from 'rxjs';
import { IncompleteProcessService } from './incomplete-process.service';
import { Store } from '@ngrx/store';
import { logOutActions } from 'src/features/auth/actions.auth';


@Injectable({
    providedIn: 'root'
})
export class GlobalHttpInterceptorService implements HttpInterceptor {
    constructor(private router: Router,
        private incompleteProcessService: IncompleteProcessService,
        private store: Store,
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return next.handle(req).pipe(
            tap(() => {
            }),
            catchError((error) => {
                if (error instanceof HttpErrorResponse) {
                    if (error.error instanceof ErrorEvent) {
                        console.error("Error Event");
                    } else {
                        switch (error.status) {
                            case 400:
                                if (error.status === 400 && error.url?.includes('microsoftonline') && error.url.includes('token')) {
                                    this.store.dispatch(logOutActions.tryGetLogout());
                                }
                                break;
                            case 401:
                                break;
                            case 403:
                                break;
                            case 500:
                                this.incompleteProcessService.isShow.next(true);
                                break;
                        }
                    }
                } else {
                    console.error("some thing else happened");
                }
                return throwError(error);
            })
        )
    }
}
