import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class KToastService {

  constructor(public messageService: MessageService) { }


  showAlertMessage(
    severity?: string, summary?: string, detail?: string, life?: number, styleClass?: string,
    data?: {
      icon?: string;
      backgroundColor?: string;
      apiUrl?: string;
      apiStatus?: number;
    }) {
    this.messageService.add({
      key: 'campaign-edit', life, severity, summary, detail, contentStyleClass: 'custom-tost-temp',
      // sticky: true,
      styleClass,
      data: {
        icon: data?.icon || '',
        backgroundColor: data?.backgroundColor || '',
        apiUrl: data?.apiUrl || '',
        apiStatus: data?.apiStatus || ''
      }
    });


  }
}
