import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { UsersRequestService } from 'src/app/pages/home/inner-pages/user-management/user-management-services/users-request.service';
import { userRoleListActions } from './action.userRole';
import { UserRoleList } from 'src/app/pages/home/inner-pages/user-management/user.constant';

@Injectable()
export class UserRoleEffect {
    userRoles$ = createEffect(() => this.actions$.pipe(
        ofType(userRoleListActions.tryGetUserRoleList),
        switchMap(() => this.usersRequestService.getUserRoles()),
        map((data): UserRoleList[] => {
            const listOfUserRoles = this.transformUserRolesData(data);
            return listOfUserRoles
        }),
        map((data) => userRoleListActions.successGetUserRoleList({ userRoleList: data })),
        catchError((error: any, caught) => {
            this.store.dispatch(userRoleListActions.failGetUserRoleList({ error }));
            return caught;
        })
    ));

    transformUserRolesData(data: any): UserRoleList[] {
        return data.data.map((role: any) => ({
            id: role.id,
            roleName: role.attributes.role_name,
            users: role.attributes.users_permissions_users?.data?.map((user: any) => user.id) || [],
        }));
    }

    constructor(
        private store: Store,
        private actions$: Actions,
        private usersRequestService: UsersRequestService,
    ) { }
}
