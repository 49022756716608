import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { LetModule } from '@ngrx/component';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { reducers } from 'src/features';
import { AuthEffect } from 'src/features/auth/effects.auth';
import { FileEffect } from 'src/features/common/effects.common';
import { CampaignEffect } from 'src/features/campaign/effects.campaign';
import { NetworkEffect } from 'src/features/network/effects.network';
import { TvEffect } from 'src/features/tv/effects.tv';
import { RoomEffect } from 'src/features/room/effects.room';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NotFoundModule } from './pages/shared/components/not-found/not-found.module';
import { LoaderInterceptorService } from './pages/shared/components/loader/services/loader-interceptor.service';
import { UsersEffect } from 'src/features/users/effects.users';
import { CountryEffect } from 'src/features/country/effects.country';
import { MessageService } from 'primeng/api';
import { GlobalHttpInterceptorService } from './pages/shared/components/incomplete-process-modal/services/global-http-interceptor.service';
import { BrowserCacheLocation, IPublicClientApplication, InteractionType, LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { environment } from 'src/environments/environment';
import { MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalBroadcastService, MsalGuard, MsalGuardConfiguration, MsalInterceptor, MsalInterceptorConfiguration, MsalModule, MsalRedirectComponent, MsalService } from '@azure/msal-angular';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { CustomSerializer } from 'src/features/store-custom-configuration/custom-serializer';
import { UnauthorizedModule } from './pages/shared/components/unauthorized/unauthorized.module';
import { ShareIdCampaignService } from './pages/home/inner-pages/campaign-management/campaign-services/share-id-campaign.service';
import { SyncEffect } from 'src/features/sync/effects.sync';
import { VersionEffect } from 'src/features/version/effects.version';
import { ReleaseNoteEffect } from 'src/features/release-note/effects.release';
import { DigitalSignageEffect } from 'src/features/digital-signage/effects.digital';
import { LastActivityEffect } from 'src/features/last-activity-user/effects.last-activity';
import { UserRoleEffect } from 'src/features/user-roles/effects.userRole';


const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1; // Remove this line to use Angular Universal

export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log('Exception has been occured here:' + message);
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      // clientId: '6226576d-37e9-49eb-b201-ec1eeb0029b6', // Prod enviroment. Uncomment to use.
      clientId: environment.clientId, // PPE testing environment
      // authority: 'https://login.microsoftonline.com/common', // Prod environment. Uncomment to use.
      authority: `https://login.microsoftonline.com/${environment.tenantId}`, // PPE testing environment.
      redirectUri: `${environment.angularLink}/auth/microsoft`,
      postLogoutRedirectUri: environment.angularLink + '/login',
      navigateToLoginRequestUrl: false
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11. Remove this line to use Angular Universal
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false
      }
    }
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set('https://graph.microsoft-ppe.com/v1.0/me.r', []); // ['user.read']

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      // scopes: ['user.read']
    },
    loginFailedRoute: '/login' // make a faliure login page
  };
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NotFoundModule,
    BrowserAnimationsModule,
    HttpClientModule,
    UnauthorizedModule,
    LetModule,
    MsalModule,
    StoreModule.forRoot(reducers),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: false,
      autoPause: true,
      features: {
        pause: false,
        lock: true,
        persist: true
      }
    }),

    EffectsModule.forRoot([
      AuthEffect,
      CampaignEffect,
      TvEffect,
      RoomEffect,
      NetworkEffect,
      FileEffect,
      UsersEffect,
      CountryEffect,
      SyncEffect,
      VersionEffect,
      ReleaseNoteEffect,
      DigitalSignageEffect,
      LastActivityEffect,
      UserRoleEffect
    ]),
    StoreRouterConnectingModule.forRoot({
      serializer: CustomSerializer
    })
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptorService, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: GlobalHttpInterceptorService, multi: true },
    MessageService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    // {
    //   provide: JWT_OPTIONS, useValue: JWT_OPTIONS
    // },
    // JwtHelperService,

    MsalService,
    MsalGuard,
    MsalBroadcastService,
    ShareIdCampaignService

  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }
