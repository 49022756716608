import { createActionGroup, emptyProps, props } from "@ngrx/store";

export const digitalSignageListActions = createActionGroup({
    source: 'Digital Signage List',
    events: {
        'try get digital signage list': props<{ pageIndex?: number, gridPageSize?: number, searchTerm?: any, filter?: any, allItem?: boolean }>(),
        'success get digital signage list': props<{ digitalSignageList: any }>(),
        'fail get digital signage list': props<{ error: Error }>(),
    },
});

export const createDigitalSignageActions = createActionGroup({
    source: 'Digital Signage Create',
    events: {
        'try create digital signage': emptyProps(),
        'success create digital signage': props<{ digitalSignageList: any }>(),
        'fail create digital signage': props<{ error: Error }>(),
    },
});

export const getDigitalSignageDetailsActions = createActionGroup({
    source: 'Digital Signage Details',
    events: {
        'try get digital signage details': props<{ digitalSignageId: any }>(),
        'success get digital signage details': props<{ digitalSignageDetails: any }>(),
        'fail get digital signage details': props<{ error: Error }>(),
    },
});


export const updateDigitalSignageActions = createActionGroup({
    source: 'Digital Signage Update',
    events: {
        'try update digital signage': props<{ digitalSignageId: number; digitalSignage: any; status: string }>(),
        'success update digital signage': props<{ digitalSignageDetails: any }>(),
        'fail update digital signage': props<{ error: Error }>(),
    }
});


export const setNumberOfDigitalSignagePerPage = createActionGroup({
    source: 'Digital Signage Pagination',
    events: {
        'try set number of digital signage': props<{ numberOfDigitalSignagePerPage: number }>(),
        'success set number of digital signage': props<{ numberOfDigitalSignagePerPage: number }>(),
        'fail set number of digital signage': props<{ error: Error }>(),
    }
});


export const setNumberOfDigitalSignagePageIndex = createActionGroup({
    source: 'Digital Signage Page index',
    events: {
        'try set number of digital signage page index': props<{ numberOfDigitalSignagePageIndex: number }>(),
        'success set number of digital signage page index': props<{ numberOfDigitalSignagePageIndex: number }>(),
        'fail set number of digital signage page index': props<{ error: Error }>(),
    }
});

export const deleteDigitalSignageActions = createActionGroup({
    source: 'Digital Signage Delete Action',
    events: {
      'try delete digital signage': props<{ digitalSignageId: number }>(),
      'success delete digital signage': emptyProps(),
      'fail delete digital signage': props<{ error: Error }>(),
    },
  });