import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, switchMap } from 'rxjs';
import { CommonHeaders } from 'src/app/pages/shared/services/common-headers';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RoomRequestService extends CommonHeaders {

  constructor(
    protected override http: HttpClient,
    protected override store: Store,
  ) {
    super(http, store);
  }

  getRooms(page): Observable<any> {
    return this.getStrapiHeaders(true).pipe(
      switchMap(({ headers }) => this.http.get<any>(
        `${environment.strapiLinkApi}/rooms?populate=*`,
        { headers }
      ))
    );
  }

  getRoomDetails(id: number): Observable<any> {
    return this.getStrapiHeaders(true).pipe(
      switchMap(({ headers }) => this.http.get<any>(
        `${environment.strapiLinkApi}/rooms/${id}?populate=deep`,
        { headers }
      ))
    );
  }

  getCreatedByList(): Observable<any> {
    return this.getMicrosoftHeaders().pipe(
      switchMap(({ headers }) => this.http.get<any>(
        `https://graph.microsoft.com/v1.0/places/microsoft.graph.room?$select=id,emailAddress,displayName,address,capacity`,
        { headers }
      ))
    );
  }

  createRooms(roomsList): Observable<any> {

    return this.getStrapiHeaders(true).pipe(
      switchMap(({ headers }) => this.http.put<any>(
        `${environment.strapiLinkApi}/createRooms`,
        roomsList,
        { headers }
      ))
    );
  }
}
