import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, map, switchMap, tap } from 'rxjs';
import { CommonHeaders } from 'src/app/pages/shared/services/common-headers';
import { environment } from 'src/environments/environment';
import { StrapiJWT } from 'src/models/active-directory-login.model';

@Injectable({
  providedIn: 'root'
})
export class CommonService extends CommonHeaders {

  constructor(
    protected override http: HttpClient,
    protected override store: Store,
  ) {
    super(http, store);
  }

  getStrapiJWT(accessToken: string): Observable<StrapiJWT> {
    return this.http.get<StrapiJWT>(`${environment.strapiLink}/api/auth/microsoft/callback?access_token=${accessToken}`);
  }

  uploadFile(campaignContent): Observable<any> {
    const formData = new FormData();
    formData.append('files', campaignContent.file);

    return this.getStrapiHeaders(true).pipe(
      switchMap(({ headers }) => this.http.post<any>(
        `${environment.strapiLinkApi}/upload`,
        formData,
        { headers: headers.delete('Content-Type') }
      )),
      // tap(() => {
      //   debugger
      // }),
      map(response => ({ response, campaignContent }))
    );
  }


  deleteFile(id: any): Observable<any> {
    return this.getStrapiHeaders(true).pipe(
      switchMap(({ headers }) => this.http.delete<any>(
        `${environment.strapiLinkApi}/upload/files/${id}`,
        { headers: headers.delete('Content-Type') }
      )),
      map(response => ({ response, id }))

    );
  }
}
