export enum UserActiveStatus {
    Active = 'active',
    Away = 'away',
    Offline = 'offline'
}

export enum UserRoleEnum {
    ContentCreator = 'content_creator',
    ContentApprover = 'content_approver',
    SystemAdmin = 'system_admin',
    ContentManager = 'content_manager',
    InfrastructureAdministrator = 'infrastructure_administrator'
}

export const UserRoleDisplayNames: { [key in UserRoleEnum]: string } = {
    [UserRoleEnum.ContentCreator]: 'Content creator',
    [UserRoleEnum.ContentApprover]: 'Content approver',
    [UserRoleEnum.SystemAdmin]: 'System admin',
    [UserRoleEnum.ContentManager]: 'Content manager',
    [UserRoleEnum.InfrastructureAdministrator]: 'Infrastructure administrator',
};

export interface UserRoleList {
    displayName?: any;
    id: number
    roleName: string;
    users?: number[];
}


export interface StrapiUserRoleAttributes {
    role_name: string;
    createdAt: string;
    updatedAt: string;
}

export interface StrapiUserRoleData {
    id: number;
    attributes: StrapiUserRoleAttributes;
}

export interface StrapiUserRoleResponse {
    data: StrapiUserRoleData[];
}

export interface RoleSummary {
    id: number;
    role_name: string;
}