import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, switchMap } from 'rxjs';
import { CommonHeaders } from 'src/app/pages/shared/services/common-headers';
import { environment } from 'src/environments/environment';
import { AppConstants } from 'src/common/constant';


@Injectable({
  providedIn: 'root'
})
export class NetworkRequestService extends CommonHeaders {

  constructor(
    protected override http: HttpClient,
    protected override store: Store,
  ) {
    super(http, store);
  }

  getNetworks(page: any): Observable<any> {
    const searchTerm = page?.searchTerm ?? '';
    return this.getStrapiHeaders(true).pipe(
      switchMap(({ headers }) => this.http.get<any>(
        `${environment.strapiLinkApi}/networks?populate=deep&pagination[pageSize]=${page.gridPageSize ?? AppConstants.gridPageSize.size}&pagination[page]=${page.pageIndex ?? 1}&filters[$and][0][name][$containsi]=${searchTerm}`,
        { headers }
      ))
    );
  }

  getNetworkDetails(id: number): Observable<any> {
    return this.getStrapiHeaders(true).pipe(
      switchMap(({ headers }) => this.http.get<any>(
        `${environment.strapiLinkApi}/networks/${id}?populate=deep`,
        { headers }
      ))
    );
  }

  addNetwork(network): Observable<any> {
    network = {
      data: {
        ...network
      }
    };
    return this.getStrapiHeaders(true).pipe(
      switchMap(({ headers }) => this.http.post<any>(
        `${environment.strapiLinkApi}/networks`,
        network,
        { headers }
      ))
    );
  }



  updateNetwork(network: any): Observable<any> {

    const { id } = network;

    network = {
      data: {
        ...network
      }
    };

    return this.getStrapiHeaders(true).pipe(
      switchMap(({ headers }) => this.http.put<any>(
        `${environment.strapiLinkApi}/networks/${id}`,
        network,
        { headers }
      ))
    );
  }


  deleteNetwork({ networkId }): Observable<any> {

    return this.getStrapiHeaders(true).pipe(
      switchMap(({ headers }) => this.http.delete<any>(
        `${environment.strapiLinkApi}/networks/${networkId}`,
        { headers }
      ))
    );
  }

  // Inside the network [strapi backend] you will find the controller
  customSendEmail(user): Observable<any> {
    user = {
      data: {
        ...user
      }
    };
    return this.getStrapiHeaders(true).pipe(
      switchMap(({ headers }) => this.http.post<any>(
        `${environment.strapiLinkApi}/networks/send-email`,
        user,
        { headers }
      ))
    );
  }
}
