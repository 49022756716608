import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IncompleteProcessService {

  public isShow: BehaviorSubject<boolean> = new BehaviorSubject(false);

}
