import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { TVRequestService } from 'src/app/pages/home/inner-pages/tv/tv-services/tv-request.service';
import * as tvAction from './actions.tv';
import { Router } from '@angular/router';
@Injectable()
export class TvEffect {


  tvList$ = createEffect(() => this.actions$.pipe(
    ofType(tvAction.tvListActions.tryGetTvList),

    switchMap((page) => this.tvRequestService.getTvs(page)),

    map((data) => tvAction.tvListActions.successGetTvList({ tvList: data })),
    catchError((error: any, caught) => {
      this.store.dispatch(tvAction.tvListActions.failGetTvList({ error }));
      return caught;
    })
  ));

  tvDetails$ = createEffect(() => this.actions$.pipe(
    ofType(tvAction.getTvDetailsActions.tryGetTvDetails),
    switchMap(({ tvId }) => this.tvRequestService.getTvDetails(tvId)),
    map((data) => tvAction.getTvDetailsActions.successGetTvDetails({ tvDetails: { data } })),
    catchError((error: any, caught) => {
      if (!error?.data) {
        this.router.navigate(['/']);
      }
      this.store.dispatch(tvAction.getTvDetailsActions.failGetTvDetails({ error }));
      return caught;
    })
  ));

  updateTv$ = createEffect(() => this.actions$.pipe(
    ofType(tvAction.updateTvActions.tryUpdateTv),
    switchMap(({ tvId, tv, status }) => this.tvRequestService.updateTv(tvId, tv, status)),
    map((data) => tvAction.updateTvActions.successUpdateTv({ tvDetails: { data } })),
    catchError((error: any, caught) => {
      this.store.dispatch(tvAction.updateTvActions.failUpdateTv({ error }));
      return caught;
    })
  ));

  constructor(
    private store: Store,
    private router: Router,
    private actions$: Actions,
    private tvRequestService: TVRequestService,


  ) { }
}
