import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { catchError, map, switchMap } from 'rxjs';
import { ReleaseNoteRequestService } from 'src/app/pages/home/inner-pages/release-note/release-note-service/release-note.service';
import { releaseNoteActionsForCMS, releaseNoteActionsForTV } from './actions.release';

@Injectable()
export class ReleaseNoteEffect {

    releaseNoteForCMS$ = createEffect(() => this.actions$.pipe(
        ofType(releaseNoteActionsForCMS.tryGetOneReleaseNoteForCms),
        switchMap(({ releaseType, versionId }) => this.releaseNoteRequestService.getReleaseNotes({ releaseType, versionId })),
        map((data) => releaseNoteActionsForCMS.successGetOneReleaseNoteForCms({ releaseNoteForCMS: data })),
        catchError((error: any, caught) => {
            this.store.dispatch(releaseNoteActionsForCMS.failGetOneReleaseNoteForCms({ error }));
            return caught;
        })
    ));

    releaseNoteForTV$ = createEffect(() => this.actions$.pipe(
        ofType(releaseNoteActionsForTV.tryGetOneReleaseNoteForTV),
        switchMap(({ releaseType, versionId }) => this.releaseNoteRequestService.getReleaseNotes({ releaseType, versionId })),
        map((data) => releaseNoteActionsForTV.successGetOneReleaseNoteForTV({ releaseNoteForTV: data })),
        catchError((error: any, caught) => {
            this.store.dispatch(releaseNoteActionsForTV.failGetOneReleaseNoteForTV({ error }));
            return caught;
        })
    ));

    constructor(
        private store: Store,
        private actions$: Actions,
        private releaseNoteRequestService: ReleaseNoteRequestService,
    ) { }
}