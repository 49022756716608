import { createActionGroup, emptyProps, props } from "@ngrx/store";
import { UserRoleList } from "src/app/pages/home/inner-pages/user-management/user.constant";


export const userRoleListActions = createActionGroup({
    source: 'User Role',
    events: {
        'try get user role list': emptyProps(),
        'success get user role list': props<{ userRoleList: UserRoleList[] }>(),
        'fail get user role list': props<{ error: Error }>(),
    },
});

