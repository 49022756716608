import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';
import { CampaignRequestService } from 'src/app/pages/home/inner-pages/campaign-management/campaign-services/campaign-request.service';
import { KToastService } from 'src/app/pages/shared/components/kToast/services/kToast.service';
import * as campaignAction from './actions.campaign';

@Injectable()
export class CampaignEffect {

  campaignList$ = createEffect(() => this.actions$.pipe(
    ofType(
      campaignAction.campaignListActions.tryGetCampaignList
    ),
    switchMap((criteria) => this.campaignRequestService.getCampaigns(criteria)),
    map((data) => campaignAction.campaignListActions.successGetCampaignList({ campaignList: { data } })),
    catchError((error: any, caught) => {
      this.store.dispatch(campaignAction.campaignListActions.failGetCampaignList({ error }));
      return caught;
    })
  ));

  campaignDetails$ = createEffect(() => this.actions$.pipe(
    ofType(campaignAction.getCampaignDetailsActions.tryGetCampaignDetails),
    switchMap(({ campaignId }) => this.campaignRequestService.getCampaignDetails(campaignId)),
    filter(data => !!data),
    map((data) => campaignAction.getCampaignDetailsActions.successGetCampaignDetails({ campaignDetails: { data } })),
    catchError((error: any, caught) => {
      this.store.dispatch(campaignAction.getCampaignDetailsActions.failGetCampaignDetails({ error }));
      if (!error?.data) {
        // If the data empty and the campaignDetails does not exist navigate into home page.
        this.router.navigate(['/']);
      }
      return caught;
    })
  ));

  addCampaign$ = createEffect(() => this.actions$.pipe(
    ofType(campaignAction.addCampaignActions.tryAddCampaign),
    switchMap(({ campaign }) => this.campaignRequestService.addCampaign(campaign)),
    map(() => campaignAction.addCampaignActions.successAddCampaign({ campaignDetails: undefined })),
    tap(() => {
      this.router.navigate(['/home/campaign-management']);

      this.kToastService.showAlertMessage(
        'success',
        "Success!",
        "You’ve successfully added a campaign",
        1500,
        'tost-class',
        { icon: 'fa-duotone fa-check', backgroundColor: ' #49D16F' });

    }),
    catchError((error: any, caught) => {
      this.store.dispatch(campaignAction.addCampaignActions.failAddCampaign({ error }));
      return caught;
    })
  ), { dispatch: false });

  updateCampaign$ = createEffect(() => this.actions$.pipe(
    ofType(campaignAction.updateCampaignActions.tryUpdateCampaign),
    switchMap(({ campaignId, campaign, contentsToDelete }) => this.campaignRequestService.updateCampaign(campaignId, campaign, contentsToDelete)),
    map(() => campaignAction.addCampaignActions.successAddCampaign({ campaignDetails: undefined })),
    tap(() => {
      this.router.navigate(['/home/campaign-management']);

      this.kToastService.showAlertMessage(
        'success',
        "Success!",
        "You’ve successfully updated a campaign",
        1500,
        'tost-class',
        { icon: 'fa-duotone fa-check', backgroundColor: ' #49D16F' });


    }),
    catchError((error: any, caught) => {
      this.store.dispatch(campaignAction.addCampaignActions.failAddCampaign({ error }));
      return caught;
    })
  ), { dispatch: false });

  deleteCampaign$ = createEffect(() => this.actions$.pipe(
    ofType(campaignAction.deleteCampaignActions.tryDeleteCampaign),
    switchMap((id) => this.campaignRequestService.deleteCampaign(id)),
    map(() => campaignAction.deleteCampaignActions.successDeleteCampaign()),
    tap(() => {
      this.router.navigate(['/home/campaign-management']);
    }),
    catchError((error: any, caught) => {
      this.store.dispatch(campaignAction.deleteCampaignActions.failDeleteCampaign({ error }));
      return caught;
    })
  ));

  updatePublishCampaign$ = createEffect(() => this.actions$.pipe(
    ofType(campaignAction.publishCampaignActions.tryPublishCampaign),
    switchMap(({ campaign, publishStatus }) => {
      return this.campaignRequestService.publishCampaign(campaign, publishStatus).pipe(
        map(result => {
          return {
            result,
            publishStatus
          }
        }),
      )
    }),
    map(({ publishStatus }) => { return campaignAction.publishCampaignActions.successPublishCampaign({ publishStatus }) }),
    catchError((error: any, caught) => {
      this.store.dispatch(campaignAction.publishCampaignActions.failPublishCampaign({ error }));
      return caught;
    })
  ));


  updateSendToAdminCampaign$ = createEffect(() => this.actions$.pipe(
    ofType(campaignAction.sendToAdminCampaignActions.trySendToAdminCampaign),
    switchMap(({ campaignId }) => {
      return this.campaignRequestService.sentToAdminCampaign(campaignId).pipe(
        map(result => {
          return {
            result
          }
        }),
      )
    }),
    map(() => { return campaignAction.sendToAdminCampaignActions.successSendToAdminCampaign() }),
    tap(() => {
      this.kToastService.showAlertMessage(
        'success',
        "Success!",
        "Send to admin",
        1500,
        'tost-class',
        { icon: 'fa-duotone fa-check', backgroundColor: ' #49D16F' });
    }),
    catchError((error: any, caught) => {
      this.store.dispatch(campaignAction.sendToAdminCampaignActions.failSendToAdminCampaign({ error }));
      return caught;
    })
  ));

  createdByList$ = createEffect(() => this.actions$.pipe(
    ofType(campaignAction.createdByListActions.tryGetListCreatedBy),
    switchMap(() => this.campaignRequestService.getCreatedByList()),
    map((data) => campaignAction.createdByListActions.successGetListCreatedBy({ createdByList: data })),
    catchError((error: any, caught) => {
      this.store.dispatch(campaignAction.campaignListActions.failGetCampaignList({ error }));
      return caught;
    })
  ));


  defaultCampaign$ = createEffect(() => this.actions$.pipe(
    ofType(
      campaignAction.defaultCampaignActions.tryGetDefaultCampaign
    ),
    switchMap((page) => this.campaignRequestService.getDefaultCampaign()),
    map((data) => campaignAction.defaultCampaignActions.successGetDefaultCampaign({ defaultCampaign: data })),
    catchError((error: any, caught) => {
      this.store.dispatch(campaignAction.defaultCampaignActions.failGetDefaultCampaign({ error }));
      return caught;
    })
  ));

  orderCampaigns$ = createEffect(() => this.actions$.pipe(
    ofType(
      campaignAction.orderCampaignsActions.tryOrderCampaigns
    ),
    switchMap(({ orderingContainer }) => this.campaignRequestService.orderCampaigns(orderingContainer)),
    map((data) => campaignAction.orderCampaignsActions.successOrderCampaigns()),
    tap(() => {
      this.kToastService.showAlertMessage(
        'success',
        "Success!",
        "The order of your campaign has been set successfully",
        1500,
        'tost-class',
        { icon: 'fa-duotone fa-check', backgroundColor: ' #49D16F' });

    }),
    catchError((error: any, caught) => {
      this.store.dispatch(campaignAction.orderCampaignsActions.failOrderCampaign({ error }));
      return caught;
    })
  ));

  orderContents$ = createEffect(() => this.actions$.pipe(
    ofType(
      campaignAction.orderContentsActions.tryOrderContents
    ),
    switchMap(({ orderingContainer }) => this.campaignRequestService.orderContents(orderingContainer)),
    map((data) => campaignAction.orderContentsActions.successOrderContents()),
    tap(() => {
      this.kToastService.showAlertMessage(
        'success',
        "Success!",
        "The order of your content has been set successfully",
        1500,
        'tost-class',
        { icon: 'fa-duotone fa-check', backgroundColor: ' #49D16F' });

    }),
    catchError((error: any, caught) => {
      this.store.dispatch(campaignAction.orderContentsActions.failOrderContents({ error }));
      return caught;
    })
  ));

  constructor(
    private store: Store,
    private actions$: Actions,
    private campaignRequestService: CampaignRequestService,
    private router: Router,
    private kToastService: KToastService

  ) { }
}
