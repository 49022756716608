import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { NetworkRequestService } from 'src/app/pages/home/inner-pages/network/network-services/network-request.service';
import * as networkAction from './actions.network';


@Injectable()
export class NetworkEffect {


    networkList$ = createEffect(() => this.actions$.pipe(
        ofType(
            networkAction.networkListActions.tryGetNetworkList,
        ),
        switchMap((page) => this.networkRequestService.getNetworks(page)),
        map((data) => networkAction.networkListActions.successGetNetworkList({ networkList: data })),
        catchError((error: any, caught) => {
            this.store.dispatch(networkAction.networkListActions.failGetNetworkList({ error }));
            return caught;
        })
    ));

    networkDetails$ = createEffect(() => this.actions$.pipe(
        ofType(networkAction.getNetworkDetailsActions.tryGetNetworkDetails),
        switchMap(({ networkId }) => this.networkRequestService.getNetworkDetails(networkId)),
        map((data) => networkAction.getNetworkDetailsActions.successGetNetworkDetails({ networkDetails: { data } })),
        catchError((error: any, caught) => {
            this.store.dispatch(networkAction.getNetworkDetailsActions.failGetNetworkDetails({ error }));
            return caught;
        })
    ));



    addNetwork$ = createEffect(() => this.actions$.pipe(
        ofType(networkAction.addNetworkActions.tryAddNetwork),
        switchMap(({ network }) => this.networkRequestService.addNetwork(network)),
        map(() => networkAction.addNetworkActions.successAddNetwork()),
        catchError((error: any, caught) => {
            this.store.dispatch(networkAction.addNetworkActions.failAddNetwork({ error }));
            return caught;
        })
    ));


    updateNetwork$ = createEffect(() => this.actions$.pipe(
        ofType(networkAction.updateNetworkActions.tryUpdateNetwork),
        switchMap(({ network }) => this.networkRequestService.updateNetwork(network)),
        map(() => networkAction.updateNetworkActions.successUpdateNetwork()),
        catchError((error: any, caught) => {
            this.store.dispatch(networkAction.updateNetworkActions.failUpdateNetwork({ error }));
            return caught;
        })
    ));


    deleteNetwork$ = createEffect(() => this.actions$.pipe(
        ofType(networkAction.deleteNetworkActions.tryDeleteNetwork),
        switchMap((id) => this.networkRequestService.deleteNetwork(id)),
        map(() => networkAction.deleteNetworkActions.successDeleteNetwork()),
        tap(() => {
            this.store.dispatch(networkAction.setNumberOfNetworkPageIndex.trySetNumberOfPageIndex({ numberOfNetworkPageIndex: 1 }));
        }),
        catchError((error: any, caught) => {
            this.store.dispatch(networkAction.deleteNetworkActions.failDeleteNetwork({ error }));
            return caught;
        })
    ));


    SendEmail$ = createEffect(() => this.actions$.pipe(
        ofType(networkAction.customSendEmailActions.trySendEmail),
        switchMap(({ user }) => this.networkRequestService.customSendEmail(user)),

        map(() => networkAction.customSendEmailActions.successSendEmail()),
        catchError((error: any, caught) => {
            this.store.dispatch(networkAction.customSendEmailActions.failSendEmail({ error }));
            return caught;
        })
    ));

    constructor(
        // private router: Router,
        private store: Store,
        private actions$: Actions,
        private networkRequestService: NetworkRequestService,

        // private api: ApisService,
        // public popOverCtrl: PopoverController,
        // private msalService: MsalService,
        // private msalBroadcastService: MsalBroadcastService

    ) { }
}
