import { createActionGroup, emptyProps, props } from "@ngrx/store";

export const tvListActions = createActionGroup({
    source: 'Tv',
    events: {
        'try get tv list': props<{ pageIndex?: number, gridPageSize?: number, searchTerm?: any, filter?: any, allItem?: boolean }>(),
        'success get tv list': props<{ tvList: any }>(),
        'fail get tv list': props<{ error: Error }>(),
    },
});

export const createTvActions = createActionGroup({
    source: 'Tv',
    events: {
        'try create tv': emptyProps(),
        'success create tv': props<{ tvList: any }>(),
        'fail create tv': props<{ error: Error }>(),
    },
});

export const getTvDetailsActions = createActionGroup({
    source: 'Tv',
    events: {
        'try get tv details': props<{ tvId: any }>(),
        'success get tv details': props<{ tvDetails: any }>(),
        'fail get tv details': props<{ error: Error }>(),
    },
});


export const updateTvActions = createActionGroup({
    source: 'Tv',
    events: {
        'try update tv': props<{ tvId: number; tv: any; status: string }>(),
        'success update tv': props<{ tvDetails: any }>(),
        'fail update tv': props<{ error: Error }>(),
    }
});


export const setNumberOfTvsPerPage = createActionGroup({
    source: 'pagination',
    events: {
        'try set number of tvs': props<{ numberOfTvsPerPage: number }>(),
        'success set number of tvs': props<{ numberOfTvsPerPage: number }>(),
        'fail set number of tvs': props<{ error: Error }>(),
    }
});


export const setNumberOfTvPageIndex = createActionGroup({
    source: 'Page index',
    events: {
        'try set number of tv page index': props<{ numberOfTvPageIndex: number }>(),
        'success set number of tv page index': props<{ numberOfTvPageIndex: number }>(),
        'fail set number of tv page index': props<{ error: Error }>(),
    }
});