import { AccountInfo, AuthenticationResult } from "@azure/msal-browser";
import { createAction, createActionGroup, emptyProps, props } from "@ngrx/store";
import { StrapiJWT } from "src/models/active-directory-login.model";

// export const strapiLoginActions = createActionGroup({
//   source: 'strapiLogin',
//   events: {
//     'try strapi login': props<{ accessToken: string }>(),
//     'strapi login success': props<{ strapiToken: any }>(),
//     'strapi login failure': props<{ error: Error }>(),
//   },
// });

export const microsoftActiveDirectoryLogin = createActionGroup({
  source: 'microsoft Auth',
  events: {
    'microsoft active directory login': props<{ account?: AccountInfo }>(),
    'microsoft active directory login success': props<{ microsoftToken: AuthenticationResult }>(),
    'microsoft active directory failure': props<{ error: Error }>(),
  }
});


export const strapiLoginActions = createActionGroup({
  source: 'Auth',
  events: {
    'strapi login': props<{ accessToken: string }>(),
    'strapi login success': props<{ strapiToken: StrapiJWT }>(),
    'strapi login failure': props<{ error: Error }>(),
  },
});

export const logOutActions = createActionGroup({
  source: 'Home',
  events: {
    'try get logout': emptyProps(),
    'success logout': emptyProps(),
    'failure logout': props<{ error: Error }>()
  }
});

export const startApplicationAction = createAction(
  '[auth] start application'
);