import { createFeature, createReducer, on } from '@ngrx/store';
import { AppConstants } from 'src/common/constant';
import * as campaignAction from './actions.campaign';

export interface CampaignState {
    campaignList: any;
    campaignDetails: any;
    createdByList: any;
    alternatingCampaignsPerPage: number;
    numberOfPageIndex: number;
    defaultCampaign: any;

}

const initialCampaignState: CampaignState = {
    campaignList: undefined,
    campaignDetails: undefined,
    createdByList: undefined,
    alternatingCampaignsPerPage: AppConstants.gridPageSize?.size,
    numberOfPageIndex: 1,
    defaultCampaign: undefined
};

export const campaignReducer = createFeature(
    {
        name: 'campaignReducer',
        reducer: createReducer(
            initialCampaignState,
            on(campaignAction.campaignListActions.successGetCampaignList,
                (state, { campaignList }) => ({ ...state, campaignList })),

            on(campaignAction.getCampaignDetailsActions.successGetCampaignDetails,
                (state, { campaignDetails }) => ({ ...state, campaignDetails })),

            on(campaignAction.addCampaignActions.successAddCampaign,
                (state, { campaignDetails }) => ({ ...state, campaignDetails })),

            on(campaignAction.createdByListActions.successGetListCreatedBy,
                (state, { createdByList }) => ({ ...state, createdByList })),

            on(campaignAction.setNumberOfCampaignPerPage.trySetNumberOfCampaign,
                (state, { alternatingCampaignsPerPage }) => {
                    return { ...state, alternatingCampaignsPerPage }
                }),

            on(campaignAction.setNumberOfPageIndex.trySetNumberOfPageIndex,
                (state, { numberOfPageIndex }) => {
                    return { ...state, numberOfPageIndex }
                }),

            on(campaignAction.defaultCampaignActions.successGetDefaultCampaign,
                (state, { defaultCampaign }) => ({ ...state, defaultCampaign })),
        )
    }
);
