/* eslint-disable @typescript-eslint/naming-convention */

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { map, skipWhile } from 'rxjs/operators';
import { selectMicrosoftToken, selectStrapiToken } from 'src/features/auth/selectors.auth';


export class CommonHeaders {

  constructor(
    protected http: HttpClient,
    protected store: Store,
  ) { }

  protected getStrapiHeaders(isAuthorized: boolean): Observable<{ headers: HttpHeaders }> {
    return isAuthorized ? this.store.select(selectStrapiToken).pipe(
      skipWhile(({ jwt }) => jwt === ''),
      map(({ jwt }) => ({
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: ` Bearer ${jwt}`,
        }),
      }))
    ) : of({
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
      })
    });
  }

  protected getMicrosoftHeaders(): Observable<{ headers: HttpHeaders }> {
    return this.store.select(selectMicrosoftToken).pipe(
      skipWhile(({ accessToken }) => accessToken === ''),
      map(({ accessToken }) => ({
        headers: new HttpHeaders({
          Authorization: ` Bearer ${accessToken}`,
        }),
      }))
    )
  }
}
