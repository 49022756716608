import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of, switchMap } from 'rxjs';
import { CommonHeaders } from 'src/app/pages/shared/services/common-headers';
import { AppConstants } from 'src/common/constant';
import { environment } from 'src/environments/environment';


@Injectable({
    providedIn: 'root'
})
export class UsersRequestService extends CommonHeaders {

    constructor(
        protected override http: HttpClient,
        protected override store: Store,
    ) {
        super(http, store);
    }

    getUsers(criteria: any): Observable<any> {

        const searchTerm = criteria?.searchTerm ?? '';
        const filter = criteria?.filter ?? '';
        let finalPageSize = criteria?.gridPageSize ?? AppConstants.gridPageSize.size;
        let filters = '';
        if (filter) {
            if (filter.department?.length > 0) {
                filter.department.forEach((departmentValue, index) => {
                    filters += `&filters[$and][0][$or][${index}][Department][$eq]=${departmentValue.key}`;
                });
            }

            if (filter.userRole?.length > 0) {
                filter.userRole.forEach((userRoleValue, index) => {
                    filters += `&filters[$and][0][$or][${index}][user_roles][role_name][$eq]=${userRoleValue.key}`;
                });
            }

            if (filter.active) {
                filters += `&filters[$and][0][isActive][$eq]=${filter.active === 'true'}`;
            }


        }
        debugger
        let apiLink = `users?populate=*&pagination[pageSize]=${finalPageSize}&pagination[page]=${criteria.pageIndex ?? 1}&filters[$and][0][username][$containsi]=${searchTerm}${filters}`
        if (criteria.allItem) {
            apiLink = `campaigns?populate=*${filters}`
        }
        return this.getStrapiHeaders(true).pipe(
            switchMap(({ headers }) => this.http.get<any>(
                `${environment.strapiLinkApi}/${apiLink}`,
                { headers }
            ))
        );
    }

    getUser(criteria: any): Observable<any> {
        const filter = criteria?.filter ?? '';
        let filters = '';

        if (filter) {

            if (filter.email) {
                filters += `&filters[$and][0][email][$eqi]=${filter.email}`;
            }
        }

        let apiLink = `users?populate=*${filters}`;
        return this.getStrapiHeaders(true).pipe(
            switchMap(({ headers }) => this.http.get<any>(
                `${environment.strapiLinkApi}/${apiLink}`,
                { headers }
            ))
        );
    }

    getUsersDetails(id: number): Observable<any> {
        return this.getStrapiHeaders(true).pipe(
            switchMap(({ headers }) => this.http.get<any>(
                `${environment.strapiLinkApi}/users/${id}?populate=*`,
                { headers }
            ))
        );
    }


    updateUser(userId, user, data): Observable<any> {
        const newUser = {
            Department: data.department,
            user_roles: data.idsOfUserRoles
        };
        return this.getStrapiHeaders(true).pipe(
            switchMap(({ headers }) => this.http.put<any>(
                `${environment.strapiLinkApi}/users/${userId}?populate=*`,
                newUser,
                { headers }
            ))
        );
    }

    updateUserIsActive(userId: number, status: boolean): Observable<any> {
        const newUser = status === true ? {
            isActive: status
        } : {
            isActive: status,
            activeStatus: 'offline'
        };
        return this.getStrapiHeaders(true).pipe(
            switchMap(({ headers }) => this.http.put<any>(
                `${environment.strapiLinkApi}/users/${userId}?populate=*`,
                newUser,
                { headers }
            ))
        );
    }

    getSyncUserInfo(): Observable<any> {
        return this.getMicrosoftHeaders().pipe(
            switchMap(({ headers }) => this.http.get<any>(
                `https://graph.microsoft.com/beta/me`,
                { headers }
            ))
        );
    }


    updateSyncUserInfo(userId, displayName): Observable<any> {
        const newUser = {
            displayName: displayName
        };
        return this.getStrapiHeaders(true).pipe(
            switchMap(({ headers }) => this.http.put<any>(
                `${environment.strapiLinkApi}/users/${userId}?populate=*`,
                newUser,
                { headers }
            ))
        );
    }


    convertBlobToBase64(blob: Blob): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64data = reader.result as string;
                resolve(base64data);
            };
            reader.onerror = reject;

            reader.readAsDataURL(blob);
        });
    }


    async getSyncUserPhoto(accessToken): Promise<Observable<any>> {
        const requestOptions: Object = {
            headers: new HttpHeaders().append(`Authorization`, `Bearer ${accessToken}`),
            responseType: 'blob'
        }

        try {
            const imageBlob = await this.http.get<any>(
                `https://graph.microsoft.com/v1.0/me/photo/$value`,
                requestOptions
            ).toPromise();

            const base64data = await this.convertBlobToBase64(imageBlob);

            return of(base64data);
        } catch (error) {
            console.error('Error fetching or converting user photo:', error);
            throw error;
        }


    }

    updateSyncUserPhoto(userId, profilePictureId): Observable<any> {

        return this.getStrapiHeaders(true).pipe(
            switchMap(({ headers }) => this.http.put<any>(
                `${environment.strapiLinkApi}/users/${userId}?populate=*`,
                {

                    profilePicture: profilePictureId

                },
                { headers }
            ))
        );
    }

    updateLastLoginUser(userId: number, lastLoginDateForUser: Date): Observable<any> {
        const newUser = {
            lastLogin: lastLoginDateForUser,
            activeStatus: 'active'
        };
        return this.getStrapiHeaders(true).pipe(
            switchMap(({ headers }) => this.http.put<any>(
                `${environment.strapiLinkApi}/users/${userId}?populate=*`,
                newUser,
                { headers }
            ))
        );
    }

    updateUserStatusActive(userId: number, statusActive: string): Observable<any> {
        const newUser = {
            activeStatus: statusActive
        };
        return this.getStrapiHeaders(true).pipe(
            switchMap(({ headers }) => this.http.put<any>(
                `${environment.strapiLinkApi}/users/${userId}?populate=*`,
                newUser,
                { headers }
            ))
        );
    }

    getLastCheckTimeUser(): Observable<any> {
        return this.getStrapiHeaders(true).pipe(
            switchMap(({ headers }) => this.http.get<any>(
                `${environment.strapiLinkApi}/user-last-check-time`,
                { headers }
            ))
        );
    }

    updateLastCheckTimeUser(): Observable<any> {
        const newTime = {
            userLastCheckTime: new Date()
        };
        return this.getStrapiHeaders(true).pipe(
            switchMap(({ headers }) => this.http.put<any>(
                `${environment.strapiLinkApi}/user-last-check-time`,
                {
                    data: {
                        ...newTime
                    }
                },
                { headers }
            ))
        );
    }

    updateLastActivityUser(activityName, activityKey): Observable<any> {
        const newLastActivity = {
            activityName,
            activityKey
        };
        return this.getStrapiHeaders(true).pipe(
            switchMap(({ headers }) => this.http.post<any>(
                `${environment.strapiLinkApi}/users-activities/update-user-activity`,
                newLastActivity,
                { headers }
            ))
        );
    }

    getUserRoles(): Observable<any> {
        return this.getStrapiHeaders(true).pipe(
            switchMap(({ headers }) => this.http.get<any>(
                `${environment.strapiLinkApi}/user-roles?populate=*`,
                { headers }
            ))
        );
    }


}

