import { createFeature, createReducer, on } from '@ngrx/store';
import { releaseNoteActionsForCMS, releaseNoteActionsForTV } from './actions.release';

export interface ReleaseNoteState {
    releaseNoteForCMS: any;
    releaseNoteForTV: any;

}

const initialReleaseNoteState: ReleaseNoteState = {
    releaseNoteForCMS: undefined,
    releaseNoteForTV: undefined,
};

export const ReleaseNoteReducer = createFeature(
    {
        name: 'ReleaseNoteReducer',
        reducer: createReducer(
            initialReleaseNoteState,
            on(releaseNoteActionsForCMS.successGetOneReleaseNoteForCms,
                (state, { releaseNoteForCMS }) => ({ ...state, releaseNoteForCMS })
            ),
            on(releaseNoteActionsForTV.successGetOneReleaseNoteForTV,
                (state, { releaseNoteForTV }) => ({ ...state, releaseNoteForTV })
            )

        )
    }
);

