/* eslint-disable @typescript-eslint/naming-convention */
import { createFeature, createReducer, on } from '@ngrx/store';
import { microsoftActiveDirectoryLogin, strapiLoginActions } from './actions.auth';
import { StrapiJWT } from 'src/models/active-directory-login.model';
import { AccountInfo } from '@azure/msal-browser';

export interface AuthenticationResult {
    authority: string;
    uniqueId: string;
    tenantId: string;
    scopes: Array<string>;
    account?: AccountInfo | null;
    idToken: string;
    idTokenClaims: any;
    accessToken: string;
    fromCache: boolean;
    expiresOn: Date | null;
    extExpiresOn?: Date;
    refreshOn?: Date;
    tokenType: string;
    correlationId: string;
    requestId?: string;
    state?: string;
    familyId?: string;
    cloudGraphHostName?: string;
    msGraphHost?: string;
    code?: string;
    fromNativeBroker?: boolean;
}

export interface AuthState {
    microsoftToken: AuthenticationResult;
    strapiToken: StrapiJWT;
}

const initialAuthState: AuthState = {
    microsoftToken: {
        accessToken: '',
        account: null,
        authority: '',
        correlationId: '',
        expiresOn: null,
        fromCache: false,
        idToken: '',
        idTokenClaims: null,
        scopes: [],
        tenantId: '',
        tokenType: '',
        uniqueId: '',
        cloudGraphHostName: '',
        code: '',
    },
    strapiToken: {
        jwt: '',
        user: {
            blocked: false,
            confirmend: false,
            craetedAt: '',
            email: '',
            id: 0,
            provider: '',
            updatedAt: '',
            username: '',
        }
    }
};

export const authReducer = createFeature(
    {
        name: 'authReducer',
        reducer: createReducer(
            initialAuthState,
            on(strapiLoginActions.strapiLoginSuccess, (state, { strapiToken }) => ({ ...state, strapiToken })),
            on(microsoftActiveDirectoryLogin.microsoftActiveDirectoryLoginSuccess,
                (state, { microsoftToken }) => ({ ...state, microsoftToken })),
        )
    }
);
