import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, filter, switchMap } from 'rxjs';
import { CommonHeaders } from 'src/app/pages/shared/services/common-headers';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ReleaseNoteRequestService extends CommonHeaders {

    constructor(
        protected override http: HttpClient,
        protected override store: Store,
    ) {
        super(http, store);
    }

    getReleaseNotes(criteria): Observable<any> {

        const { releaseType, versionId } = criteria;
        let filter = '';

        if (releaseType === 'CMS' || releaseType === 'TV') {
            filter = `&filters[release_type][$eq]=${releaseType}&filters[version_id][id][$eq]=${versionId}`;
        }

        return this.getStrapiHeaders(true).pipe(
            switchMap(({ headers }) => this.http.get<any>(
                `${environment.strapiLinkApi}/release-notes?populate=*${filter}`,
                { headers }
            ))
        );
    }
}
