import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ShareIdCampaignService {

  private CampaignId: number;

  setSharedCampaignId(id: number) {
    this.CampaignId = id;
  }

  getSharedCampaignId(): number {
    return this.CampaignId;
  }
}
