import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { UsersRequestService } from 'src/app/pages/home/inner-pages/user-management/user-management-services/users-request.service';
import { updateLastActivityActions } from './actions.last-activity';


@Injectable()
export class LastActivityEffect {
    updateLastLastActivityUser$ = createEffect(() => this.actions$.pipe(
        ofType(updateLastActivityActions.tryUpdateLastActivityUser),
        switchMap(({ activityName, activityKey }) => this.usersRequestService.updateLastActivityUser(activityName, activityKey)),
        catchError((error: any, caught) => {
            this.store.dispatch(updateLastActivityActions.failUpdateLastActivityUser({ error }));
            return caught;
        })
    ), { dispatch: false });


    constructor(
        private store: Store,
        private actions$: Actions,
        private usersRequestService: UsersRequestService,
        private router: Router,

    ) { }
}