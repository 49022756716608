import { createActionGroup, props } from "@ngrx/store";


export const updateLastActivityActions = createActionGroup({
    source: 'Last Activity User',
    events: {
        'try update Last Activity user': props<{ activityName: string, activityKey: string }>(),
        'success update Last Activity user': props<{ lastActivityDetails: any }>(),
        'fail update Last Activity user': props<{ error: Error }>(),
    },
});