import { createFeature, createReducer, on } from '@ngrx/store';
import * as digitalSignageAction from './actions.digital';
import { AppConstants } from 'src/common/constant';


export interface DigitalSignageState {
    digitalSignageList: any;
    digitalSignage: any;
    numberOfDigitalSignagePerPage: number;
    numberOfDigitalSignagePageIndex: number;
    digitalSignageDetails: any;
}

const initialTvState: DigitalSignageState = {
    digitalSignageList: undefined,
    digitalSignage: undefined,
    numberOfDigitalSignagePerPage: AppConstants.gridPageSize?.size,
    numberOfDigitalSignagePageIndex: 1,
    digitalSignageDetails: 1,
};

export const digitalSignageReducer = createFeature(
    {
        name: 'digitalSignageReducer',
        reducer: createReducer(
            initialTvState,
            on(digitalSignageAction.digitalSignageListActions.successGetDigitalSignageList,
                (state, { digitalSignageList }) => ({ ...state, digitalSignageList })),

            on(digitalSignageAction.getDigitalSignageDetailsActions.successGetDigitalSignageDetails,
                (state, { digitalSignageDetails }) => ({ ...state, digitalSignageDetails })),

            on(digitalSignageAction.setNumberOfDigitalSignagePerPage.trySetNumberOfDigitalSignage,
                (state, { numberOfDigitalSignagePerPage }) => ({ ...state, numberOfDigitalSignagePerPage })),

            on(digitalSignageAction.setNumberOfDigitalSignagePageIndex.trySetNumberOfDigitalSignagePageIndex,
                (state, { numberOfDigitalSignagePageIndex }) => ({ ...state, numberOfDigitalSignagePageIndex })),

            on(digitalSignageAction.updateDigitalSignageActions.successUpdateDigitalSignage,
                (state, { digitalSignageDetails }) => ({ ...state, digitalSignageDetails })),
        )
    }
);
