import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { roomListActions, getRoomDetailsActions, syncRoomsActions, createRoomsActions } from './actions.room';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { RoomRequestService } from 'src/app/pages/home/inner-pages/room/room-services/room-request.service';

@Injectable()
export class RoomEffect {


  roomList$ = createEffect(() => this.actions$.pipe(
    ofType(roomListActions.tryGetRoomList),

    switchMap((page) => this.roomRequestService.getRooms(page)),
    map((data) => roomListActions.successGetRoomList({ roomList: data })),
    catchError((error: any, caught) => {
      this.store.dispatch(roomListActions.failGetRoomList({ error }));
      return caught;
    })
  ));

  roomDetails$ = createEffect(() => this.actions$.pipe(
    ofType(getRoomDetailsActions.tryGetRoomDetails),
    switchMap(({ roomId }) => this.roomRequestService.getRoomDetails(roomId)),
    map((data) => getRoomDetailsActions.successGetRoomDetails({ roomDetails: { data } })),
    catchError((error: any, caught) => {
      this.store.dispatch(getRoomDetailsActions.failGetRoomDetails({ error }));
      return caught;
    })
  ));

  syncRooms$ = createEffect(() => this.actions$.pipe(
    ofType(syncRoomsActions.trySyncRoomsDetails),

    switchMap(() => this.roomRequestService.getCreatedByList()),
    map((data) => syncRoomsActions.successSyncRoomsDetails({ syncedRoomList: data })),
    catchError((error: any, caught) => {
      this.store.dispatch(syncRoomsActions.failSyncRoomsDetails({ error }));
      return caught;
    })
  ));

  createRooms$ = createEffect(() => this.actions$.pipe(
    ofType(createRoomsActions.tryCreateRooms),
    switchMap(({ roomsList }) => this.roomRequestService.createRooms(roomsList)),
    map((data) => createRoomsActions.successCreateRooms()),
    catchError((error: any, caught) => {
      this.store.dispatch(createRoomsActions.failCreateRooms({ error }));
      return caught;
    })
  ));

  constructor(
    private store: Store,
    private actions$: Actions,
    private roomRequestService: RoomRequestService,
  ) { }
}
