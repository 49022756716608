import { createFeature, createReducer, on } from '@ngrx/store';
import { lastVersionActions } from './actions.version';

export interface VersionState {
    lastVersion: any;
    version: any;
    versionDetails: any;
}

const initialVersionState: VersionState = {
    lastVersion: undefined,
    version: undefined,
    versionDetails: undefined,
};

export const versionReducer = createFeature(
    {
        name: 'versionReducer',
        reducer: createReducer(
            initialVersionState,
            on(lastVersionActions.successGetLastVersion,
                (state, { lastVersion }) => ({ ...state, lastVersion }))

        )
    }
);

