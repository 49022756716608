import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { countryListActions, getCountryDetailsActions } from './actions.country';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { CountryRequestService } from 'src/app/pages/home/inner-pages/room/room-services/country-request.service';

@Injectable()
export class CountryEffect {


  countryList$ = createEffect(() => this.actions$.pipe(
    ofType(countryListActions.tryGetCountryList),
    
    switchMap((page) => this.countryRequestService.getCountries(page)),
    map((data) => countryListActions.successGetCountryList({ countryList: data })),
    catchError((error: any, caught) => {
      this.store.dispatch(countryListActions.failGetCountryList({ error }));
      return caught;
    })
  ));

  countryDetails$ = createEffect(() => this.actions$.pipe(
    ofType(getCountryDetailsActions.tryGetCountryDetails),
    switchMap(({ countryId }) => this.countryRequestService.getCountryDetails(countryId)),
    map((data) => getCountryDetailsActions.successGetCountryDetails({ countryDetails: { data } })),
    catchError((error: any, caught) => {
      this.store.dispatch(getCountryDetailsActions.failGetCountryDetails({ error }));
      return caught;
    })
  ));


  constructor(
    // private router: Router,
    private store: Store,
    private actions$: Actions,
    private countryRequestService: CountryRequestService,

    // private api: ApisService,
    // public popOverCtrl: PopoverController,
    // private msalService: MsalService,
    // private msalBroadcastService: MsalBroadcastService

  ) { }
}
